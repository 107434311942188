@import "../../skin/css/sg/variables/variables_less";
@import "../../skin/css/sg/mixins_less";

.separatorHorizontal {
    border:           none;
    margin:           1rem 0 2rem 0;
    display:          block;
    background-color: @gray8;
    border-top:       1px solid transparent;
    width:            100%;

    &Empty {
        margin: 0;
    }

    &.separatorHorizontalLong {
        max-width: 1480px;
    }

    &Secondary {
        background-color: @gray7;
    }
}

.separatorHorizontal.separatorHorizontalInner {
    max-width: 1170px;
    margin:    auto;
}

.separatorHorizontal.separatorHorizontalInner.separatorLanding {
    max-width:        initial;
    background-color: @gray7;
}

hr {
    border:           0;
    margin:           0;
    background-color: @gray7;
    height:           1px;
}
