@import '../../../skin/css/sg/variables/variables_less';
@import '../../../skin/css/sg/mixins_less';

.spinner {
    position: fixed;
    top:      0;
    bottom:   15%;
    left:     0;
    right:    0;
    height:   55px;
    padding:  5%;
    margin:   auto;

    &Wrapper {
        background: rgba(0, 0, 0, 0.5);
        position:   fixed;
        top:        0;
        right:      0;
        bottom:     0;
        left:       0;
        opacity:    1;
        z-index:    100;
        transition: all 0.15s ease-out;
        display:    none;

        &Mini {
            background-color: transparent;
            position:         static;
            height:           200px;
            width:            100%;

            .spinner {
                position: static;
            }
        }

        &MobileView {
            background-color: transparent;
            position:         static;
            height:           200px;
            width:            100%;

            .spinner {
                top:    20%;
                bottom: auto;
            }
        }

        &Show {
            display: block;
        }

        &Btn {
            background-color: transparent;
            position:         static;
            display:          block;
            height:           100%;

            .spinner {
                position: static;
                height:   100%;
                padding:  0;
                margin:   -3px auto 0;
            }
        }
    }

    &Text {
        text-align: center;
    }
}

.spinnerCircle {
    position: relative;
    margin:   0px auto;
    width:    55px;

    &:before {
        content:     '';
        display:     block;
        padding-top: 100%;
    }
}

.circular {
    stroke:           @green1;
    animation:        rotate 2s linear infinite;
    height:           100%;
    transform-origin: center center;
    width:            100%;
    position:         absolute;
    top:              0;
    bottom:           0;
    left:             0;
    right:            0;
    margin:           auto;

    &Green {
        stroke: @green;
    }

    &Btn {
        stroke: #fff;
        height: 75%;
    }
}

.path {
    stroke-dasharray:  1, 200;
    stroke-dashoffset: 0;
    animation:         dash 1.5s ease-in-out infinite;
    stroke-linecap:    round;
    strokeWidth:       2;
    strokeMiterlimit:  10;
}

@-webkit-keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes dash {
    0% {
        stroke-dasharray:  1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray:  89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray:  89, 200;
        stroke-dashoffset: -124;
    }
}

@keyframes dash {
    0% {
        stroke-dasharray:  1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray:  89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray:  89, 200;
        stroke-dashoffset: -124;
    }
}

.spinnerRelative {
    position: relative;
    padding:  0 0 4%;
    height:   95px;

    @media only screen and (max-width: (@tablet)) {
        padding: 0 0 10%;
    }

    .spinnerCircle {
        width: 95px;
    }
}

.spnr {
    height:            25px;
    width:             25px;
    border-radius:     50%;
    border:            3px solid transparent;
    animation:         spin 1s linear infinite;

    background:        linear-gradient(white, white), conic-gradient(from 0.15turn, white, @green);
    background-origin: border-box;
    background-clip:   content-box, border-box;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
