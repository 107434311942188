.fruitBox {
  background-color: rgba(255,255,255,.2);
  border-radius: 18px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.1);
  width: 130px;
  left: 10px;
  bottom: 170px;
  flex-direction: column;
}

.fruitBoxImage {
  width: 100%;
  border-radius: 18px 18px 0 0;
  height: 80px;
}
.fruitBoxImage img {
  border-radius: 18px 18px 0 0;
  position: relative;
  z-index: 1;
  height: 80px;
  width: 100%;
  object-fit: cover;
}
.fruitBoxInfo {
  width: 100%;
  padding: 5px;
  text-align: center;
}

.fruitBoxImage {
  width: 100%;
  border-radius: 18px 18px 0 0;
  height: 80px;
}
.fruitBoxImage img {
  border-radius: 18px 18px 0 0;
  position: relative;
  z-index: 1;
  height: 80px;
  width: 100%;
  object-fit: cover;
}
.fruitBoxInfo {
  width: 100%;
  padding: 5px;
  text-align: center;
}

.fruitBoxInfo h3 {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin-bottom: 10px;
}

.fruitBoxInfo span {
  width: 80px;
  height: 30px;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00A050;
  border-radius: 20px;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin: 0 auto;
}

@media (min-width: 552px) {
  .fruitBox {
    width: 250px;
    left: 10px;
    bottom: 170px;
    flex-direction: row;
  }
  .fruitBoxImage {
    width: 120px;
    border-radius: 18px 0 0 18px;
    height: 120px;
  }
  .fruitBoxImage img {
    border-radius: 18px 0 0 18px;
    height: 120px;
    width: 120px;
  }
  .fruitBoxInfo {
    width: calc(100% - 120px);
    padding: 0 5px;
    text-align: left;
  }
  .fruitBoxInfo span {
    margin: 0;
  }
}

@media (min-width: 768px) {
  .fruitBox {
    left: -40px;
    bottom: 240px;
  }
  .fruitBoxInfo {
    padding: 0 10px 0 10px;
  }
  .fruitBoxInfo h3 {
    font-size: 14px;
    line-height: 20px;
  }
  .fruitBoxInfo span {
    width: 80px;
    height: 30px;
    font-size: 10px;
    line-height: 12px;
  }
}
@media (min-width: 840px) {
  .fruitBox {
    width: 350px;
    left: -70px;
    bottom: 220px;
  }
}

@media (min-width: 1281px) {
  .fruitBox {
    bottom: 0px;
  }
}

@media (min-width: 1320px) {
  .fruitBox {
    bottom: 220px;
  }
}

@media (min-width:1641px) {
  .fruitBox {
    bottom: 227px;
    left: -120px;
  }
}
@media (min-width: 1281px) {
  .fruitBox {
    bottom: 0px;
  }
}

@media (min-width: 1320px) {
  .fruitBox {
    bottom: 200px;
  }
}

@media (min-width:1641px) {
  .fruitBox {
    bottom: 227px;
    left: -120px;
  }
}