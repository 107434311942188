@import '../../../../../../skin/css/sg/variables/variables_less';
@import '../../../../../../skin/css/sg/mixins_less';

.mobileMenuDots {
    position:         absolute;
    line-height:      @col_top_height_mobile;
    height:           @col_top_height_mobile;
    right:            8px;
    top:              2px;
    display:          none;
    background-color: transparent;
    border:           none;
    cursor:           pointer;
    margin:           0;
    padding:          0;

    &Right {
        right: 40px !important;
    }

    @media only screen and (max-width: (@big_width)) {
        display: block;
    }

    @media only screen and (max-width: (@mobile)) {
        right: 17px;
    }

    .mobileMenuDot {
        height: 18px;
        width:  40px;
        fill:   #ffffff;
    }
}
