@import "../../../styles/common_less.module.less";
@import '../../../../skin/css/sg/variables/variables_less';

.Auth {
    background-position: center;
    background-size:     cover;
    padding-top:         69px;
    min-height:          450px;
    box-sizing:          content-box !important;
    display:             flex;
    flex-grow:           1;
    //align-items:         center;
    flex-direction:      column;

    @media screen and (max-width: (@mobile)) {
        min-height:       auto;
        background-image: none !important;
        background-color: #fff;
        padding-top:      60px;
    }
}

.Bottom {

    &Link {
        font-size:       0.9em;
        font-weight:     600;
        transition:      all 0.2s ease-in-out;
        cursor:          pointer;
        text-decoration: none;
        color:           @primary;

        &:visited {
            color: @primary;
        }

        &:hover {
            text-decoration: underline;
        }

        &Gray {
            color:       @gray6 !important;
            font-size:   12px;
            font-weight: 400;
            margin-top:  25px;
            display:     block;
        }
    }
}

.wrapper {
    background-color: @gray8;
    min-height:       450px;
    box-sizing:       content-box !important;
    display:          flex;
    flex-grow:        1;
    align-items:      center;
    flex-direction:   column;
    padding-top:      69px;

    @media only screen and(max-width: (@big_width)) {
        padding-top: 59px;
    }

    @media only screen and (max-width: (@middle_mobile)) {
        background: #ffffff;
    }
}

.mainBlock {
    border-radius:    5px;
    box-sizing:       border-box;
    width:            100%;
    max-width:        500px;
    position:         relative;
    background-color: #ffffff;
    text-align:       center;
    box-shadow:       0 0 16px rgba(0, 0, 0, 0.06);
    //box-shadow:         0 4px 12px 1px @gray1_15; /* старая тень */
    z-index:          2;
    padding:          34px 80px 47px;
    margin:           100px auto 260px;

    @media only screen and (max-width: (@middle_mobile)) {
        top:           0;
        width:         100%;
        height:        100%;
        max-width:     100%;
        border-radius: 0;
        padding:       32px 16px 155px;
        margin:        0;
    }

    @media only screen and (max-width: (@mobile)) {
        box-shadow: none;
    }

    &Long {
        max-width:     555px;
        padding-left:  45px;
        padding-right: 45px;

        @media only screen and (max-width: (@middle_mobile)) {
            max-width:     100%;
            padding-left:  16px;
            padding-right: 16px;
        }

        .mainBlockText {
            max-width: 100%;
        }
    }

    &Title {
        line-height:    1.7;
        letter-spacing: 0.6px;

        @media only screen and(max-width: (@tablet)) {
            line-height:   1.4;
            margin-bottom: 15px;
        }
    }

    &Text {
        max-width: 250px;
        margin:    0 auto;

        @media only screen and (max-width: (@middle_mobile)) {
            max-width: 100%;
        }

        &Long {
            max-width: 100%;
        }
    }

    .formWrapper {
        text-align: left;
        margin:     36px auto 0;
        width:      340px;
        @media only screen and(max-width: (@tablet)) {
            margin: 33px auto 0;
        }
        @media only screen and (max-width: (@middle_mobile)) {
            width:     100%;
            max-width: 300px;
        }
        @media only screen and (max-width: (@min_mobile)) {
            width: auto;
        }
    }

    .bottomRow {
        display:         flex;
        justify-content: space-between;
        align-items:     center;
        margin-top:      40px;
        margin-bottom:   0;

        @media only screen and (max-width: (@middle_mobile)) {
            flex-direction: column-reverse;
            margin-top:     30px;
        }
    }

    .bottomRowCenter {
        justify-content: center;
        text-align:      center;
        margin-top:      25px;
    }

    .bottomCol {
        display:         flex;
        flex-direction:  column;
        align-items:     center;
        margin-bottom:   0;
        justify-content: center;
        text-align:      center;
        margin-top:      25px;
        gap:             20px;

        @media only screen and(max-width: (@middle_mobile)) {
            margin-top: 30px;
        }
    }

    .bottomLink {
        font-size:      12px;
        font-weight:    600;
        letter-spacing: 0.22px;

        @media only screen and (max-width: (@middle_mobile)) {
            margin-top: 22px;
        }
    }

    .bottomLinkCenter {
        font-size:      14px;
        font-weight:    600;
        letter-spacing: 0.14px;
    }

    .securityQuestion {
        font-weight:   600;
        margin-bottom: 20px;
    }
}

/* popups styles BEGIN--- */

div.sessionPopup {
    max-width:  433px;
    text-align: left;
}

.bottomButtonsRight {
    display:         flex;
    justify-content: flex-end;
    text-align:      center;
    margin-top:      25px;

    @media only screen and (max-width: (@middle_mobile)) {
        justify-content: space-between;

        & * {
            overflow: hidden;
            width:    50%;
        }
    }
}

.secondBtn {
    margin-left: 20px;

    @media only screen and (max-width: (@middle_mobile)) {
        margin-left: 10px;
    }
}

.popupTitle {
    letter-spacing: 0.4px;
    margin-bottom:  12px;

    @media only screen and (max-width: (@tablet)) {
        font-size:   20px;
        line-height: 1.5;
        margin-top:  3px;
    }
}

.popupText {
    letter-spacing: 0.3px;
}

a.mobileCloseBlock {
    @media only screen and (min-width: (@middle_mobile)) {
        display: none;
    }
}

/* ---popups styles END */

.formError {
    input {
        border:           1px solid @danger;
        background-color: @red_tint3;
    }
}

.error {
    font-size:     12px;
    font-weight:   600;
    color:         @danger;
    text-align:    center;
    margin-bottom: 30px;
    display:       block;
}

.successMessage {
    font-size:     12px;
    font-weight:   600;
    color:         @special;
    text-align:    center;
    margin-bottom: 30px;
    display:       block;
}
