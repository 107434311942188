//@import "../../../styles/landing_special.less";
@import "../../../styles/common_less.module.less";

/**
 * Terms Page:
 * --------------------------------
*/

.SectionWrapper {
    box-sizing: border-box;
    padding:    70px 0 0;
    margin:     0;

    @media screen and (max-width: @tablet_horizontal) {
        padding-top: 60px;
    }

    .TitleUppercase {
        text-transform: uppercase;

        @media screen and (max-width: @big_mobile) {
            text-transform: none;
        }
    }
}

.SectionTerms {
    padding:    0 0 200px 0;
    box-sizing: border-box;

    @media screen and (max-width: @big_mobile) {
        padding: 0 0 100px 0;
    }
}

.SectionTitle {
    box-sizing: border-box;
    padding:    29px 0;
    width:      100%;
}

.SectionTitleItem {
    font-family:   'Montserrat', 'Open Sans', Courier, monospace;
    margin-top:    8px;
    margin-bottom: 4px !important;
}

.statement45 {
    font-size: 40px;
}

.statement35 {
    font-size: 22px;
}

.TermsItem {
    p, div, li, a {
        word-break: break-word;
    }
}

.TermsItemTitle {
    font-family: 'Montserrat', 'Open Sans', Courier, monospace;
    margin:      @infelicity 0;
}

.TermsBlockList {
    padding: 0 0 0 @infelicity;
}

.TermsBlockListItem {
    padding:       (@infelicity/3) 0 0 0;
    margin-bottom: 0;
    font-size:     14px;
    line-height:   24px;

    &:first-child {
        padding: 0;
    }

    &:last-child {
        margin-bottom: 24px;
    }
}

.termsPopup {
    max-width:  430px;
    max-height: 360px;
    height:     auto;
    width:      100%;
    top:        0;
    left:       0;
    right:      0;
    bottom:     0;

    .list {
        padding: 0;
        margin:  0;

        li {
            padding-bottom: 10px;
            font-size:      14px;
            line-height:    1.4;
            margin-bottom:  0;
        }
    }
}

.heading30 {
    margin-bottom: 18px;
}

.text16 {
    @media screen and (max-width: @tablet) {
        font-size: 16px;
    }
}

.linkPrimary {
    text-decoration: underline;
}

