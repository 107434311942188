@import "../../skin/css/sg/variables/variables_less";
@import "../../skin/css/sg/mixins_less";

.heading38const {
    font-family:    'Open Sans', Courier, monospace;
    margin:         0 0 24px 0;
    color:          @gray1;
    letter-spacing: 0.3px;
    font-size:      38px;
    line-height:    1.4;
    font-weight:    600;
}

.heading36const {
    font-family:    'Open Sans', Courier, monospace;
    margin:         0 0 24px 0;
    color:          @gray1;
    font-size:      36px;
    font-weight:    600;
    font-style:     normal;
    letter-spacing: 0.3px;
    line-height:    56px;
}

.heading30const {
    font-family:    'Open Sans', Courier, monospace;
    margin:         0 0 24px 0;
    color:          @gray1;
    letter-spacing: 0.3px;
    font-size:      30px;
    line-height:    44px;
    font-weight:    600;
}

.heading26const {
    font-family:    'Open Sans', Courier, monospace;
    margin:         0 0 24px 0;
    color:          @gray1;
    letter-spacing: 0.3px;
    font-size:      26px;
    line-height:    1.7;
    font-weight:    600;
}

.heading24const {
    font-family:    'Open Sans', Courier, monospace;
    margin:         0 0 24px 0;
    color:          @gray1;
    letter-spacing: 0.3px;
    font-size:      24px;
    line-height:    36px;
    font-weight:    600;
}

.heading20const {
    font-family:    'Open Sans', Courier, monospace;
    margin:         0 0 24px 0;
    color:          @gray1;
    letter-spacing: 0.3px;
    font-size:      20px;
    line-height:    30px;
    font-weight:    600;
}

.heading38 {
    .heading38const();

    @media only screen and (max-width: (@tablet)) {
        font-size: 24px;
    }
}

.heading36 {
    .heading36const();

    @media only screen and (max-width: (@tablet)) {
        font-size: 24px;
    }
}

.heading30 {
    .heading30const();

    @media only screen and (max-width: (@tablet)) {
        font-size: 24px;
    }
}

.heading26 {
    .heading26const();

    @media only screen and (max-width: (@tablet)) {
        font-size: 21px;
    }
}

.heading24 {
    .heading24const();

    @media only screen and (max-width: (@tablet)) {
        font-size: 20px;
    }
}

.heading20 {
    .heading20const();

    @media only screen and (max-width: (@tablet)) {
        font-size: 16px;
    }

    &--20 {
        //класс - особенно когда компонент готов :)
        @media only screen and (max-width: (@tablet)) {
            font-size: 20px;
        }
    }
}

.headingh2--light {
    font-size:      20px;
    font-weight:    400;
    letter-spacing: 0.3px;

    @media only screen and (max-width: (@tablet)) {
        font-size:   16px;
        line-height: normal;
    }
}

.heading16 {
    font-family:    'Open Sans', Courier, monospace;
    margin:         0 0 24px 0;
    color:          @gray1;
    letter-spacing: 0.3px;
    font-size:      16px;
    line-height:    24px;
    font-weight:    600;
}

.heading14 {
    font-family:    'Open Sans', Courier, monospace;
    margin:         0 0 24px 0;
    color:          @gray1;
    letter-spacing: 0.3px;
    font-size:      14px;
    line-height:    20px;
    font-weight:    600;
}

.heading12 {
    font-family:    'Open Sans', Courier, monospace;
    margin:         0 0 24px 0;
    color:          @gray1;
    letter-spacing: 0.3px;
    font-size:      12px;
    line-height:    1.7;
    font-weight:    600;
}

.headingBold {
    font-weight: 700;
}

.headingWhite {
    color: #ffffff;
}

.headingRegular {
    font-weight: 400;
}

.headingPublic {
    font-family: 'Montserrat', 'Open Sans', Courier, monospace;
}
