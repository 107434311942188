@import "../../skin/css/sg/variables/variables_less";
@import "../../skin/css/sg/mixins_less";

.list {
    padding-left:    15px;
    list-style-type: disc;
    font-size:       14px;

    &Number {
        list-style-type: decimal;
        padding-left:    23px;
    }

    &Circle {
        list-style-type: circle;
    }
}

.listItem {
    color:          @gray1;
    padding-bottom: 10px;
    line-height:    22px;
}

.sublist {
    list-style-type: none;
}
