@import '../../../../../skin/css/sg/variables/variables_less';
@import '../../../../../skin/css/sg/mixins_less';

.navbar {
    line-height:    70px;
    display:        inline-block;
    padding-left:   4px;
    vertical-align: top;

    @media only screen and (max-width: (@big_width)) {
        display: none;
    }
}

.navbarList {
    font-size: 0;

    @media only screen and (max-width: (@big_width)) {
        display:        flex;
        flex-direction: column;
        align-items:    flex-start;
        width:          100%;
    }

    &Divider {
        @media only screen and (max-width: (@big_width)) {
            border-top:  1px solid @gray3;
            margin-top:  8px;
            padding-top: 8px;
        }
    }
}

.contactsList {
    display: none;

    @media only screen and (max-width: (@big_width)) {
        display:        flex;
        flex-direction: column;
        align-items:    flex-start;
        border-top:     1px solid @gray3;
        width:          100%;
        padding-top:    12px;
        margin-top:     8px;
    }

    &:last-child {
        padding-bottom: 75px;
    }
}

.navbarAdmin {
    line-height:    70px;
    display:        inline-block;
    padding-left:   4px;
    vertical-align: top;
}

.navbarMobile {
    display:   flex;
    flex-wrap: wrap;
    padding:   0;

    &NoFooter {
        border-bottom:  1px solid @gray3;
        padding-bottom: 10px;
        margin-bottom:  8px;

        a:last-child {
            display: none;
        }
    }
}

// admin header
.adminDropdownList {
    li {
        letter-spacing: 0.027rem;

        &:hover {
            background-color: rgba(205, 230, 210, 0.1);
        }

        a {
            font-size: 14px;
            padding:   8px 14px 9px 15px;
            width:     100%;
        }

        a:hover,
        a:focus {
            background: transparent;
        }
    }
}

.askIt {
    display:     flex;
    align-items: center;

    &Icon {
        margin-right: 6px;
    }

    .dot {
        position: relative;

        &:before {
            content:          '';
            position:         absolute;
            right:            -4px;
            top:              -14px;
            background-color: #FD3C30;
            width:            8px;
            height:           8px;
            border-radius:    50%;
        }
    }
}
