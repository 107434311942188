.themeCard {
  min-height: 130px;
  border-radius: 22px;
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.06);
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: #FFFFFF;
}

.themeCardMedia {
  min-height: 130px;
  height: 100%;
  max-width: 120px;
  border-radius: 22px 0 0 22px;
  display: flex;
}
.themeCardMedia img,
.themeCardMedia iframe {
  min-height: 130px;
  height: 100%;
  max-width: 120px;
  object-fit: cover;
  border-radius: 22px 0 0 22px;
}

.themeCardInfo {
  padding: 20px 30px 15px;
}

.themeCardTitle {
  font-size: 13px;
  line-height: 12px;
  color: #005550;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin-bottom: 5px;
  margin-top: 0;
}

.themeCardInfoText {
  font-size: 10px;
  line-height: 13px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
}

.themeCardBtn {
  display: flex;
  width: 30px;
  height: 20px;
  justify-content: center;
  align-items: center;
  background-color: #00A050;
  border: .7px solid #00A050;
  border-radius: 20px;
}
.themeCardBtn svg {
  width: 10px;
}

@media (min-width: 640px) {
  .themeCard {
    min-height: 255px;
  }
  
  .themeCardMedia {
    min-height: 255px;
    max-width: 255px;
  }
  .themeCardMedia img,
  .themeCardMedia iframe {
    min-height: 255px;
    height: 100%;
    max-width: 255px;
  }
  .themeCardInfo {
    padding: 40px 50px 35px;
  }
  .themeCardTitle {
    font-size: 24px;
    line-height: 25px;
    margin-bottom: 10px;
  }
  .themeCardInfoText {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .themeCardBtn {
    width: 60px;
    height: 40px;
    border: 1.4px solid #A0D7AF;
    border-radius: 20px;
  }
  .themeCardBtn svg {
    width: auto;
  }
}

@media (min-width: 992px) {
  .themeCard {
    min-width: 340px;
    max-width: 340px;
    min-height: 440px;
    display: flex;
    flex-direction: column;
  }
  .themeCardMedia {
    max-height: 210px;
    min-height: 210px;
    border-radius: 22px 22px 0 0;
    max-width: 100%;
    display: block;
  }
  .themeCardMedia img,
  .themeCardMedia iframe {
    max-height: 210px;
    min-height: 210px;
    object-fit: cover;
    border-radius: 22px 22px 0 0;
    max-width: 100%;
    width: 100%;
  }
  .themeCardInfo {
    padding: 40px 30px 20px;
  }
  .themeCardTitle {
    font-size: 20px;
    line-height: 20px;
  }
  .themeCardInfoText {
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 0;
  }
  .themeCardBtn {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}