@import "./variables/variables_less";

.flex-direction--media (@size: @big_mobile, @direction:column, @justify:flex-start, @align:center) {
    @media only screen and(max-width: (@size)) {
        flex-direction:  @direction;
        justify-content: @justify;
        align-items:     @align;
    }
}


.font-styles(@font_size: 14px, @line_height: 14px,@font_weight: 400) {
    font-size:   @font_size;
    line-height: @line_height;
    font-weight: @font_weight;
}

.appearance() {
    appearance:   none;
    user-select:  none;
    touch-action: manipulation;
}

.block-photos-mixin (@margin:100px) {
    width:  100%;
    margin: @margin auto 0;

    img {
        max-width: 100%;
        width:     100%;
        height:    auto;
    }

    @media screen and (max-width: (@tablet)) {
        margin-top: 60px;
    }

    &__desktop {
        min-height:          300px;
        background-repeat:   repeat-x;
        background-position: center;
        background-size:     contain;
        @media screen and (max-width: 1500px) {
            background-size: cover;
        }
    }
}

.size(@size: 1em) {
    width:  @size;
    height: @size;
}

.grid (@row:1, @column:1) {
    -ms-grid-row:    @row;
    -ms-grid-column: @column;
}

.grid-col-3(@items: 30, @counter: 1, @row: 1) when (@counter < @items) {

    &:nth-child(@{counter}) {
        -ms-grid-column: 1;
        -ms-grid-row:    @row;
    }

    &:nth-child(@{counter} + 1) {
        -ms-grid-column: 2;
        -ms-grid-row:    @row;
    }

    &:nth-child(@{counter} + 2) {
        -ms-grid-column: 3;
        -ms-grid-row:    @row;
    }

    .grid-col-3(@items, @counter + 3, @row + 1)
}

.grid-col-2_1(@items: 30, @counter: 2, @row: 1) when (@counter < @items) {

    &:nth-child(@{counter}) {
        -ms-grid-column: 1;
        -ms-grid-row:    @row;
    }

    &:nth-child(@{counter} + 1) {
        -ms-grid-column: 2;
        -ms-grid-row:    @row;
    }

    &:nth-child(@{counter} + 2) {
        -ms-grid-column: 1;
        -ms-grid-row:    @row + 1;
    }

    .grid-col-2_1(@items, @counter + 3, @row + 2)
}

.hide_scrollbar() {
    -ms-overflow-style: none;
    scrollbar-width:    none;

    &::-webkit-scrollbar {
        display: none;
    }
}

@keyframes bgPulse {
    from {
        background-color: @gray6;
    }
    50% {
        background-color: @gray7;
    }
    to {
        background-color: @gray6;
    }
}
