.blogCard {
  max-width: 330px;
}

.blogCardLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-decoration: none;
}

.blogCardImage {
  margin-bottom: 20px;
  max-width: 270px;
  min-width: 100%;
  min-height: 350px;
  max-height: 350px;
  position: relative;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.blogCardImage img {
  object-fit: cover;
  border-radius: 22px;
  max-width: inherit;
  max-height: inherit;
  max-width: 100%;
}

.blogCardImageBorder img {
  border: 1px solid var(--content-color);
}

.blogCardInfoTitle {
  font-size: 17px;
  line-height: 27px;
  color: var(--content-color);
  font-weight: 700;
  letter-spacing: .17px;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 10px;
}

.blogCardInfoExcerpt {
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: .23px;
  color: var(--content-color);
  margin-bottom: 10px;
}

.blogCardAuthoInfo {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: var(--content-color);
  max-width: 170px;
}

@media (min-width: 552px) {
  .blogCard {
    max-width: 460px;
  }
  .blogCardInfoTitle {
    font-size: 26px;
    line-height: 46px;
    letter-spacing: .26px;
  }
  .blogCardInfoExcerpt {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: .38px;
  }
  .blogCardAuthoInfo {
    font-size: 17px;
    line-height: 22px;
    max-width: 360px;
  }
  .blogCardImage {
    max-width: 100%;
    min-width: 100%;
    min-height: 520px;
    max-height: 520px;
  }
}

@media (min-width: 992px) {
  .blogCard {
    max-width: 330px;
  }
  .blogCardInfoTitle {
    font-size: 24px;
    line-height: 38px;
    letter-spacing: .24px;
  }
  .blogCardInfoExcerpt {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: .32px;
  }
  .blogCardAuthoInfo {
    font-size: 16px;
    line-height: 22px;
    max-width: 240px;
  }
  .blogCardImage {
    max-width: 100%;
    min-width: 100%;
    min-height: 370px;
    max-height: 370px;
  }
}