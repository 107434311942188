.themeHero {
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.themeHeroContainer {
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.themeHeroContent {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
}
.themeHeroContentInner {
  width: 100%;
}
.themeHeroContentInner {
  margin: 0 auto;
}
.themeHeroAnimation {
  width: 100%;
  position: relative;
  text-align: center;
  margin-top: 50px;
  height: 600px;
}

.themeHeroAnimation .heroAnimationImage {
  max-width: 100%;
  position: relative;
  z-index: 2;
  height: 600px;
  object-fit: cover;
}

.themeHeroHeading {
  font-size: 30px;
  line-height: 32px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin-bottom: 20px;
  color: #fff;
  margin-top: 0;
}
.themeHeroHeading span {
  display: block;
  font-weight: 800;
}
.themeHeroText {
  font-family: 'Open Sans', sans-serif;
  font-family: 15px;
  line-height: 20px;
  letter-spacing: .22px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 20px;
  max-width: 240px;
  margin-left: auto;
  margin-right: auto;
}

.themeHeroBtn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: .03px;
  font-family: 'Open Sans', regular;
  font-weight: 600;
  color: #fff;
  background-color: #00A050;
  padding: 8px 20px;
  text-transform: inherit;
  text-align: center;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  border-radius: 3px;
  border: 1px solid #00A050;
  text-decoration: none;
  -webkit-transition: .2s all ease-in-out;
  -o-transition: .2s all ease-in-out;
  transition: .2s all ease-in-out;
  margin: 0 auto;
}

.themeHeroBtn:hover,
.themeHeroBtn:focus,
.themeHeroBtn:focus-visible,
.themeHeroBtn:active {
    background-color: #00825a;
    border-color: #00825a;
}

@media (min-width: 600px) {
  .themeHeroAnimation {
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .themeHeroContainer {
    padding: 0 16px;
  }
  .themeHeroText {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: .36px;
    max-width: 390px;
  }
  .themeHeroBtn {
    font-size: 20px;
    line-height: 30px;
    padding: 12px 40px;
  }
  .themeHeroHeading {
    font-size: 60px;
    line-height: 62px;
  }
  .themeHeroAnimation {
    width: 640px;
    text-align: center;
    height: 800px;
  }
  .themeHeroContentInner {
    margin: 0;
  }
  .themeHeroAnimation .heroAnimationImage {
    height: 800px;
    right: 0;
  }
}

@media (min-width: 1281px) {
  .themeHeroContainer {
    flex-direction: row;
  }
  .themeHeroContent {
    width: calc(100% - 800px);
    text-align: left;
    padding-left: 16px;
  }
  .themeHeroAnimation {
    width: 800px;
    height: 600px;
  }
  .themeHeroAnimation .heroAnimationImage {
    height: 600px;
    right: 0;
  }
  .themeHeroBtn {
    margin: 0;
  }
  .themeHeroText {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 1320px) {
  .themeHeroContent {
    width: calc(100% - 800px);
  }
  .themeHeroAnimation,
  .themeHeroAnimation .heroAnimationImage {
    height: 800px;
  }
  .themeHeroContentInner {
    max-width: 500px;
  }
}

@media (min-width: 1641px) {
  .themeHeroContent {
    width: calc(100% - 640px);
    text-align: left;
    padding-left: 16px;
  }
  .themeHeroAnimation {
    width: 640px;
  }
  .themeHeroText {
    margin-left: 0;
    margin-right: 0;
  }
  .themeHeroHeading {
    font-size: 70px;
    line-height: 76px;
  }
  .themeHeroContentInner {
    margin: 0;
    max-width: 600px;
  }
  .themeHeroAnimation .heroAnimationImage,
  .themeHeroAnimation {
    height: auto;
  }
}