@import "../../../../styles/common_less.module.less";

.IntroduceAiPopup {
    width:      calc(~"100% - 32px");
    max-height: 80%;
    overflow-y: auto;
    text-align: center;
    padding:    52px 36px 32px;

    .text16 {
        font-size: 16px;
    }

    .heading24 {
        font-size: 24px;
    }

    &Btn {
        max-width:     100%;
        width:         100%;
        margin-bottom: 20px;

        @media screen and (max-width: (@big_mobile)) {
            width:   107px;
            display: block;
            margin:  0 auto 20px;
        }
    }

    &Link {
        font-size:      16px;
        font-style:     normal;
        font-weight:    600;
        line-height:    24px;
        letter-spacing: 0.048px;
    }

    &Icon {
        margin-bottom: -5px;
    }
}

.AgreementPopup {
    padding: 52px 21px 32px;

    &Btn {
        max-width:      137px;
        width:          137px;
        display:        block;
        margin:         0 auto 20px;
        font-size:      20px !important;
        line-height:    54px !important;
        letter-spacing: 0.06px;
        height:         54px !important;
    }

    .heading24 {
        margin-top: 20px;
    }
}