@import "../../skin/css/sg/variables/variables_less";

.Section {
    padding-top:    120px;
    padding-bottom: 120px;
    box-sizing:     border-box;

    @media all and (max-width: (@big_mobile)) {
        padding-top:    60px;
        padding-bottom: 60px;
    }

    &White {
        background-color: #fff;
    }

    &Gray {
        background-color: @gray8;
    }
}
