@import '../../../skin/css/sg/variables/variables_less';
@import '../../../skin/css/sg/mixins_less';

.closeBlock {
    position:    absolute;
    text-align:  center;
    line-height: 60px;
    height:      50px;
    width:       60px;
    right:       16px;
    top:         16px;
    padding:     0;
    z-index:     1;
    transition:  background-color 0.2s linear;
    cursor:      pointer;
    background-color: transparent;
    border: none;
    margin: 0;

    &:hover {
        background-color: #f5f5f5;
    }

    @media only screen and (max-width: (@big_mobile)) {
        top:   4px;
        right: 2px;
    }

    &Icon {
        fill:   @gray5;
        width:  40%;
        height: 40%;
    }
}
