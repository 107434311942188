.accordionWrapper {
  margin-bottom: 20px;
}
.accordionItem {
  padding: 20px 0 20px 45px;
  border-top: 1px solid #000000;
  position: relative;
}
.accordionIcon {
  position: absolute;
  top: 20px;
  left: 0;
  width: 35px;
  height: 35px;
}
.accordionItem:last-child {
  border-bottom: 1px solid #000000;
}

.accordionQuestion {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #232D37;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.accordionQuestion svg {
  width: 15px;
}

.accordionItem.isOpen .accordionQuestion {
  margin-bottom: 10px;
}

.accordionColumnImage {
  display: none;
}
.accordionColumnImage img {
  max-width: 100%;
}

.accordionAnswer {
  overflow: hidden;
  max-height: 0;
  transition: 0.2s ease-in-out;
  pointer-events: none;
}

.accordionItem.isOpen .accordionAnswer {
  overflow: auto;
  max-height: fit-content;
  transition: 0.2s ease-in-out;
  pointer-events: all;
}

.accordionAnswer p,
.accordionAnswer p strong,
.accordionAnswer strong,
.accordionAnswer p a,
.accordionAnswer a,
.accordionAnswer span,
.accordionAnswer p span,
.accordionAnswer h2,
.accordionAnswer h3,
.accordionAnswer h4,
.accordionAnswer h5,
.accordionAnswer h6 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  color: #494949;
}

@media (min-width: 992px) {
  .accordionWrapper {
    margin-bottom: 0;
  }
  .accordionWrapper {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 35px;
    justify-content: space-between;
  }
  
  .accordionColumn {
    flex-basis: calc(50% - 35px);
    max-width: calc(50% - 35px);
    width: calc(50% - 35px);
  }
  .accordionColumn.accordionColumnFull {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  }
  .accordionQuestion {
    font-size: 20px;
    line-height: 26px;
  }
  .accordionAnswer p,
  .accordionAnswer p strong,
  .accordionAnswer strong,
  .accordionAnswer p a,
  .accordionAnswer a,
  .accordionAnswer span,
  .accordionAnswer p span,
  .accordionAnswer h2,
  .accordionAnswer h3,
  .accordionAnswer h4,
  .accordionAnswer h5,
  .accordionAnswer h6 {
    font-size: 16px;
    line-height: 24px;
  }
  .accordionColumnImage {
    display: block;
  }
}