.apobBox {
  position: absolute;
  left: auto;
  right: 10px;
  top: -80px;
  padding: 10px 10px;
  background-color: rgba(255,255,255, .2);
  width: 270px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.1);
}

.apobPill {
  text-align: center;
  width: 50%;
}
.apobPill span {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFC800;
  border-radius: 50px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #fff;
  width: 70px;
  height: 30px;
  font-size: 12px;
  line-height: 12px;
}
.apobInfo {
  width: 50%;
  text-align: center;
}

.apobInfo svg {
  width: 20px;
  height: 20px;
}

.apobInfo h3 {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 14px;
  color: #fff;
  font-weight: 700;
}

.apobInfo p {
  font-size: 12px;
  color: #fff;
  opacity: .7;
}

.apobGraph {
  width: calc(320px - 40px);
  height: 50px;
  margin-top: 5px;
  position: relative;
}

.apobGraphImage {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  object-fit: cover;
  object-position: left;
  left: 0;
}

.apobGraphDot {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: rgba(255,255,255,1);
  border-radius: 100%;
}

.apobGraphDotOne {
  top: auto;
  bottom: 30%;
  left: 15%;
}
.apobGraphLine {
  background-color: rgba(255,255,255,.8);
  position: absolute;
  height: 3px;
  border-radius: 10px;
  transform-origin: left;
}
.apobGraphLineOne {
  width: 64px;
  transform: rotate(-14deg);
  top: 3px;
  left: 9px;
}
.apobGraphDotTwo {
  left: 40%;
  top: 20%;
}

.apobGraphLineTwo {
  width: 64px;
  transform: rotate(15deg);
  top: 4px;
  left: 9px;
}
.apobGraphDotThree {
  left: 65%;
  top: auto;
  bottom: 27%;
}

.apobGraphLineThree {
  width: 80px;
  transform: rotate(-21deg);
  top: 2px;
  left: 8px;
}
.apobGraphDotFour {
  right: 5px;
  top: -17px;
}
@media (min-width: 552px) {
  .apobBox {
    left: 40px;
    top: -20px;
  }
}
@media (min-width: 600px) {
  .apobBox {
    top: 0;
    left: 30px;
  }
}

@media (min-width: 768px) {
  .apobBox {
    top: 50px;
    left: 20px;
  }
  .apobPill span {
    width: 100px;
    height: 40px;
    font-size: 16px;
    line-height: 16px;
  }
  .apobInfo svg {
    width: 30px;
    height: 30px;
  }
  .apobInfo h3 {
    font-size: 20px;
    line-height: 20px;
  }
  .apobInfo p {
    font-size: 14px;
  }
  .apobGraph {
    margin-top: 20px;
  }
}

@media (min-width: 840px) {
  .apobBox {
    width: 320px;
    padding: 20px 20px;
    left: -20px;
  }
  .apobGraphDotTwo {
    top: 15%;
  }
  .apobGraphLineThree {
    width: 88px;
  }
  .apobGraphDotFour {
    right: 10px;
    top: -20px;
  }
}

@media (min-width: 1281px) {
  .apobBox {
    top: 50px;
    left: 60px;
  }
}

@media (min-width: 1641px) {
  .apobBox {
    top: 90px;
    left: -30px;
    padding: 20px 20px;
  }
}