@import "../../skin/css/sg/variables/variables_less";
@import "../../skin/css/sg/mixins_less";

.containerpdf {
    position:  relative;
    max-width: 2062px;
    min-width: 980px;
    width:     100%;
}

// desktop 1920px, mobile 100%
.container1920 {
    box-sizing: border-box;
    min-width:  @min_width;
    max-width:  1920px;
    margin:     0 auto;
    width:      100%;
}

// desktop 1170px, mobile 100%
.containerFluid {
    box-sizing: border-box;
    min-width:  @min_width;
    max-width:  @max_width;
    margin:     0 auto;
    width:      100%;
}

// desktop 1170px, mobile 320 + 16px padding
.container1170 {
    box-sizing: border-box;
    min-width:  @min_width;
    max-width:  @max_width;
    margin:     0 auto;
    width:      100%;

    @media only screen and (max-width: (@desktop)) {
        padding: 0 16px;
    }
}

// desktop 1128px, mobile 320 + 16px padding
.container1128 {
    box-sizing: border-box;
    min-width:  @min_width;
    max-width:  1128px;
    margin:     0 auto;
    width:      100%;

    @media only screen and (max-width: (@desktop)) {
        padding: 0 16px;
    }
}

.containerImagery {
    display:         flex;
    justify-content: flex-start;
    align-items:     center;
    height:          100%;
}

// desktop 940px, mobile padding 16px
// реквест от ани размер должен быть 1030 вместо 1040
// используем один раз
.container1030 {
    box-sizing: border-box;
    min-width:  @min_width;
    max-width:  1030px;
    margin:     0 auto;
    width:      100%;

    @media only screen and (max-width: (@desktop)) {
        padding: 0 16px;
    }
}

// desktop 940px, mobile padding 16px
.container940 {
    box-sizing: border-box;
    min-width:  @min_width;
    max-width:  940px;
    margin:     0 auto;
    width:      100%;

    @media only screen and (max-width: (@desktop)) {
        padding: 0 16px;
    }
}

// desktop 830px, mobile padding 16px
.container862 {
    box-sizing: border-box;
    min-width:  @min_width;
    max-width:  862px;
    margin:     0 auto;
    width:      100%;

    @media only screen and (max-width: (@desktop)) {
        padding: 0 16px;
    }
}

// desktop 866px, mobile padding 16px
.container866 {
    box-sizing: border-box;
    min-width:  @min_width;
    max-width:  866px;
    margin:     0 auto;
    width:      100%;

    @media only screen and (max-width: (@desktop)) {
        padding: 0 16px;
    }
}

// desktop 830px, mobile padding 16px
.container830 {
    box-sizing: border-box;
    min-width:  @min_width;
    max-width:  830px;
    margin:     0 auto;
    width:      100%;

    @media only screen and (max-width: (@desktop)) {
        padding: 0 16px;
    }
}

// desktop 770px, mobile padding 16px
.container770 {
    box-sizing: border-box;
    min-width:  @min_width;
    max-width:  770px;
    margin:     0 auto;
    width:      100%;

    @media only screen and (max-width: (@desktop)) {
        padding: 0 16px;
    }
}

// desktop 600px, mobile padding 16px
.container600 {
    box-sizing: border-box;
    min-width:  @min_width;
    max-width:  600px;
    margin:     0 auto;
    width:      100%;

    @media only screen and (max-width: (@desktop)) {
        padding: 0 16px;
    }
}

// desktop 560px, mobile padding 16px
.container560 {
    box-sizing: border-box;
    min-width:  @min_width;
    max-width:  560px;
    margin:     0 auto;
    width:      100%;
}

///container modifications
.containerCenter {
    text-align: center;
}

//ready
/////////////////////////////////////////////////////
//not ready todo Peter
//not today

.containerRight {
    box-sizing:       border-box;
    width:            calc(~'100% - 323px');
    width:            -moz-calc(~'100% - 323px');
    width:            -webkit-calc(~'100% - 323px');
    width:            -o-calc(~'100% - 323px');
    padding:          30px 30px 200px;
    background-color: @gray8;
    margin-left:      auto;

    @media only screen and (max-width: (@tablet_horizontal)) {
        padding-bottom: 100px;
        width:          calc(~'100% - 280px');
        width:          -moz-calc(~'100% - 280px');
        width:          -webkit-calc(~'100% - 280px');
        width:          -o-calc(~'100% - 280px');
    }
    @media only screen and (max-width: (@big_mobile)) {
        width:      100%;
        padding:    20px 20px 100px;
        margin-top: 0;
    }
}

.containerSidebar {
    padding: 16px;
}

.containerImage {
    max-height: 300px;
    overflow:   hidden;

    &Img {
        width: 100%;
    }
}

.result-slider .swiper-container-custom {
    @media only screen and (max-width: (@big_mobile)) {
        overflow: visible;
    }
}

// homepage
.containerFlex {
    display: flex;
    @media only screen and (max-width: (@big_tablet)) {
        flex-direction: column;
    }
}

// container should be the same width
.containerFlexCenter {
    display:         flex;
    justify-content: center;
    align-items:     center;
    position:        relative;
}

.containerFlexCenterWrap {
    display:         flex;
    justify-content: center;
    align-items:     center;
    flex-wrap:       wrap;
    position:        relative;
}

.containerFlexSpace {
    display:         flex;
    justify-content: space-between;
    align-items:     center;
    position:        relative;
}

.containerColumn {
    display:        flex;
    flex-direction: column;
    align-items:    center;
}

.containerRightFaq {
    background-color: #ffffff;
    padding:          20px 30px 200px;
}


.containerPaddingCenter {
    flex-direction: column;
    align-items:    center;
    text-align:     center;
    min-width:      280px;
    max-width:      765px;
    height:         available;
    margin:         auto;

    @media only screen and (max-width: (@big_tablet)) {
        min-height: 520px;
        height:     auto;
    }
}

//for onboarding, questionnaire, survey
.questionnaireContainer {
    margin: 50px auto;
    @media only screen and (max-width: (@big_mobile)) {
        margin: 0 auto 48px;
    }

    &.container770 {
        padding: 0 16px;
    }
}
