@import "../../skin/css/sg/variables/variables_less";
@import "../../skin/css/sg/mixins_less";

.hide {
    display: none;
}

.show {
    display: block;
}

.hideTablet {
    @media only screen and (max-width: (@tablet)) {
        display: none;
    }
}

.hideBigTablet {
    @media only screen and (max-width: (@big_tablet)) {
        display: none;
    }
}

.showBigTablet {
    @media only screen and (min-width: (@big_tablet)) {
        display: none;
    }
}

.showTablet {
    @media only screen and (min-width: (@show_tablet)) {
        display: none;
    }
}

.hideTabletHorizontal {
    @media only screen and (max-width: (@tablet_horizontal)) {
        display: none;
    }
}

.showTabletHorizontal {
    @media only screen and (min-width: (@show_tablet_horizontal)) {
        display: none;
    }
}

.hideBigMobile {
    @media only screen and (max-width: (@big_mobile)) {
        display: none;
    }
}

.showBigMobile {
    @media only screen and (min-width: (@show_big_mobile)) {
        display: none;
    }
}

.hideMobile {
    @media only screen and (max-width: (@mobile)) {
        display: none;
    }
}

.showMobile {
    @media only screen and (min-width: (@show_mobile)) {
        display: none;
    }
}

.hidden {
    visibility: hidden;
}

// Old styles
.hideTabletInline {
    display: inline;

    @media only screen and (max-width: (@big_mobile)) {
        display: none;
    }
}

.showTabletInline {
    display: none;

    @media only screen and (max-width: (@big_mobile)) {
        display: inline;
    }
}

.showTabletInlineBlock {
    display: none;

    @media only screen and (max-width: (@tablet)) {
        display: inline-block;
    }
}

.hideTabletInlineBlock {
    display: inline-block;

    @media only screen and (max-width: (@tablet)) {
        display: none;
    }
}

.showMobileInline {
    display: none;
    @media only screen and (max-width: (@mobile)) {
        display: inline;
    }
}

.showMobileInlineBlock {
    display: none;
    @media only screen and (max-width: (@mobile)) {
        display: inline-block;
    }
}

.hideMobileInline {
    display: inline;
    @media only screen and (max-width: (@mobile)) {
        display: none;
    }
}

.hideMobileInlineBlock {
    display: inline-block;
    @media only screen and (max-width: (@mobile)) {
        display: none;
    }
}
