@import '../../../styles/common_less.module.less';

.errorPage {
    &Container {
        display:        flex;
        flex-direction: column;
        flex-grow:      1;
        align-items:    center;
        color:          @gray1;
        font-family:    'Open Sans', Arial, sans-serif;
        text-align:     center;
        box-sizing:     border-box;
        min-width:      310px;
        max-width:      1170px;
        margin:         0 auto;
        width:          100%;
        padding:        206px 16px 195px;

        @media only screen and (max-width: (@big_mobile)) {
            padding-top:    108px;
            padding-bottom: 90px;
        }
    }

    &Title {
        color:          @gray1;
        font-size:      30px;
        font-weight:    600;
        letter-spacing: 0.4px;
        margin-top:     37px;
        margin-bottom:  20px;

        @media only screen and (max-width: (@big_mobile)) {
            font-size:     24px;
            margin-top:    29px;
            margin-bottom: 16px;
        }
    }

    &Subtitle {
        font-size:      20px;
        letter-spacing: 0.33px;
        margin:         4px 0 27px;
        font-weight:    400;

        @media only screen and (max-width: (@big_mobile)) {
            font-size: 16px;
            margin:    4px 0 19px;
        }
    }

    &Text {
        font-size:      16px;
        letter-spacing: 0.27px;
        line-height:    normal;

        @media only screen and (max-width: (@big_mobile)) {
            font-size: 14px;
        }
    }

    &Image {
        width:  273px;
        height: 151px;

        @media only screen and (max-width: (@big_mobile)) {
            width:  164px;
            height: 91px;
        }
    }
}


