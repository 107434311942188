@import '../../../../../../skin/css/sg/variables/variables_less';
@import '../../../../../../skin/css/sg/mixins_less';

.sandwich {
    position:    absolute;
    line-height: 60px;
    height:      60px;
    left:        0;
    top:         0;
    display:     none;

    @media only screen and (max-width: (@big_width)) {
        display: block;
    }

    @media only screen and (max-width: 450px) {
        line-height: 60px;
        height:      60px;
    }

    &Link {
        transition:       all 0.2s ease-in-out;
        cursor:           pointer;
        text-decoration:  none;
        font-size:        inherit;
        display:          flex;
        justify-content:  center;
        align-items:      center;
        height:           100%;
        width:            4rem;
        background-color: transparent;
        border:           none;
        margin:           0;
        padding:          0;
    }

    &Line {
        display:          inline-block;
        vertical-align:   middle;
        height:           18px;
        width:            18px;
        stroke-width:     0;
        color:            #ffffff;
        stroke:           currentColor;
        fill:             currentColor;
    }
}
