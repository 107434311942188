@import "../../skin/css/sg/variables/variables_less";
@import "../../skin/css/sg/mixins_less";

.fill {
    &Gray {
        fill: @gray1;
    }

    &Primary {
        fill: @primary;
    }
}

.icon {
    .size();
    display:      inline-block;
    stroke-width: 0;
    stroke:       currentColor;
    fill:         currentColor;

    &.close-block__icon,
    &.sidebar-team__icon {
        margin-bottom: -1px;
    }
}

.arrow {
    &Down {
        transform: rotate(90deg);
    }

    &Left {
        transform: rotate(180deg);
    }

    &Up {
        transform: rotate(270deg);
    }
}
