@import "../../skin/css/sg/variables/variables_less";
@import "../../skin/css/sg/mixins_less";

.statement90 {
    font-family:    'Montserrat', 'Open Sans', Courier, monospace;
    color:          @gray1;
    letter-spacing: -0.2px;
    font-size:      90px;
    line-height:    1;
    font-weight:    600;

    @media only screen and (max-width: (@tablet)) {
        font-size:      75px;
        letter-spacing: 0.2px;
    }
}

.statement60 {
    font-family: 'Montserrat', 'Open Sans', Courier, monospace;
    margin:      0 0 24px 0; // test
    color:       @gray1;
    font-size:   60px;
    font-weight: 600;
    line-height: 1;

    @media only screen and (max-width: (@tablet)) {
        font-size:      45px;
        letter-spacing: -0.2px;
    }
}

.statement45 {
    font-family:    'Montserrat', 'Open Sans', Courier, monospace;
    color:          @gray1;
    letter-spacing: -0.2px;
    font-size:      45px;
    line-height:    1;
    font-weight:    600;

    @media only screen and (max-width: (@tablet)) {
        font-size: 35px;
    }
}

.statement35 {
    font-family:    'Montserrat', 'Open Sans', Courier, monospace;
    color:          @gray1;
    letter-spacing: -0.2px;
    font-size:      35px;
    font-weight:    600;
    line-height:    1;
}

.statement22 {
    font-family:    'Montserrat', 'Open Sans', Courier, monospace;
    color:          @gray1;
    font-size:      22px;
    font-weight:    600;
    line-height:    1;
}

.statement16 {
    font-family:    "Open Sans Condensed", sans-serif;
    color:          @gray1;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    font-size:      16px;
    font-weight:    600;
    line-height:    1;
}

.statement14 {
    font-family:    "Open Sans Condensed", sans-serif;
    color:          @gray1;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    font-size:      14px;
    font-weight:    600;
    line-height:    1;
}

///statements modifications

.statementLight {
    font-weight: 300;
}

.statementWhite {
    color: #ffffff;
}

.statementGreen {
    color: @green1;
}

.statementBold {
    font-weight: 700;
}


