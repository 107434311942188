/**
 * INTR Style Guide Buttons React
 * --------------------------------
*/
/**
 * Buttons styles INTR
 * Buttons styles GG
 * --------------------------------
*/
@import '../../../skin/css/sg/variables/variables_less';
@import '../../../skin/css/sg/mixins_less';

.btn {
    padding:          0 20px;
    box-sizing:       border-box;
    border-radius:    3px;
    border-style:     hidden;
    background-color: transparent;
    cursor:           pointer;
    display:          inline-block;
    height:           36px;
    color:            #ffffff;
    font-size:        14px;
    font-weight:      600;
    line-height:      36px;
    text-decoration:  none;
    text-align:       center;
    transition:       all 0.2s ease-out;
    outline:          none;
    white-space:      nowrap;
    .appearance();

    &Disabled,
    &:disabled {
        cursor:         default;
        opacity:        0.3;
        pointer-events: none;
    }

    /*  btn-primary */

    &Primary {
        &:extend(.btn);
        background-color: @primary;

        /*  btn-primary-glass   */

        &Glass {
            &:extend(.btn);
            color:  @primary;
            border: 1px solid @primary;
            flex:   none;

            &Check {
                &:extend(.btn);
                border: 1px solid @gray6;
                color:  @gray1;
                cursor: default;
                flex:   none;

                &:hover,
                &:focus,
                &:active,
                &:visited {
                    border-color: @gray6;
                }
            }
        }

        /*  hover, focus, active, disabled  */

        &,
        &Glass {
            &:hover,
            &:focus {
                color:            #ffffff;
                border-color:     @primary--hover;
                background-color: @primary--hover;
            }

            &:active {
                color:            @primary--active-text;
                border-color:     @primary--active;
                background-color: @primary--active;
            }

            &Disabled,
            &:disabled {
                &:extend(.btn:disabled);
            }
        }

        &Cancel {
            background-color: @gray7;
            border-color:     @gray7;
            color:            @gray3;
            font-family:      "Open Sans - Semibold", sans-serif;
            font-size:        16px;
            font-weight:      600;
            letter-spacing:   0.3px;
            line-height:      24px;

            &:hover,
            &:focus {
                border-color:     lighten(@gray7, 8);
                background-color: lighten(@gray7, 8);
            }

            &:active {
                border-color:     darken(@gray7, 8);
                background-color: darken(@gray7, 8);
            }
        }
    }

    /*  btn-secondary   */

    &Secondary {
        &:extend(.btn);
        color:            @gray1;
        background-color: @gray7;

        &:active {
            color:            @gray4;
            background-color: @gray6;
        }

        &:hover,
        &:focus {
            color:            @gray1;
            border-color:     @gray10;
            background-color: @gray10;
        }

        &Disabled,
        &:disabled {
            &:extend(.btn:disabled);
        }

        /*  btn-secondary-glass */

        &Glass {
            &:extend(.btn);
            color:  @gray1;
            border: 1px solid @gray7;
            flex:   none;

            &:active {
                border-color:     @gray6;
                background-color: @gray6;
            }
        }

        &Glass {
            &:hover,
            &:focus {
                border-color:     @gray7;
                background-color: @gray9;
            }

            &Disabled,
            &:disabled {
                &:extend(.btn:disabled);
            }
        }

        &Dark {
            &:extend(.btn);
            background-color: @gray4;

            &:hover,
            &:focus {
                color:            #ffffff;
                background-color: @gray5;
            }

            &:active {
                color:            @gray2;
                background-color: @gray3;
            }
        }
    }

    /*  btn-green primary */

    &Green {
        font-family:      'Open Sans', Courier, monospace;
        &:extend(.btn);
        padding:          0 20px;
        min-width:        120px;
        background-color: @green;

        &:hover,
        &:active {
            background-color: @green_hover;
        }

        /*  btn-green glass */

        &Glass {
            color:  @green;
            border: 1px solid @green;

            &:hover {
                background-color: @green7;
            }
        }
    }

    /*  btn-green primary */

    &GreenDark {
        font-family:      'Open Sans', Courier, monospace;
        &:extend(.btn);
        padding:          0 22px;
        background-color: @green1;

        &:hover,
        &:active {
            background-color: @green1;
        }
    }

    /* btn-special */

    &Special {
        height:           48px;
        display:          flex;
        justify-content:  center;
        align-items:      center;
        padding-left:     26px;
        padding-right:    26px;
        font-size:        16px;
        font-weight:      600;
        letter-spacing:   0.3px;
        line-height:      24px;
        background-color: @special;
        border-color:     @special;

        &:focus, &:active {
            background-color: @special--active;
            border-color:     @special--active;
        }

        &:hover {
            background-color: @special--hover;
            border-color:     @special--hover;
        }
    }

    /* btn-ghost */

    &Ghost {
        height:           48px;
        display:          flex;
        justify-content:  center;
        align-items:      center;
        padding-left:     26px;
        padding-right:    26px;
        font-size:        16px;
        letter-spacing:   0.3px;
        line-height:      24px;
        background-color: transparent;
        border-color:     transparent;
        color:            @primary;
        font-weight:      normal;

        &:focus, &:active {
            background-color: transparent;
            border-color:     transparent;
            color:            @primary--active;
        }

        &:hover {
            background-color: transparent;
            border-color:     transparent;
            color:            @primary--hover;
        }

        &:disabled {
            border-color:     transparent;
            color:            @gray5;
            background-color: transparent;
        }
    }

    /* btn-danger */

    &Danger {
        &:extend(.btn);
        background-color: @danger;


        &:hover,
        &:focus {
            background-color: @danger--hover;
        }

        &:active {
            color:            @danger--active-text;
            background-color: @danger--active;
        }

        &Disabled,
        &:disabled {
            &:extend(.btn:disabled);
        }
    }

    &.btnLarge {
        font-size:   16px;
        height:      40px;
        line-height: 40px;
        padding:     0 22px;

        @media only screen and(max-width: (@big_mobile)) {
            font-size:   14px;
            height:      36px;
            line-height: 36px;
            padding:     0 20px;
        }
    }

    &.btnSuperLarge {
        font-size:   16px;
        height:      48px;
        line-height: 48px;
        padding:     0 30px;
    }

    &.btnGrand {
        font-size:      20px;
        height:         54px;
        line-height:    54px;
        padding:        0 40px;
        letter-spacing: 0.06px;
    }

    &.btnHuge {
        font-size:   20px;
        height:      56px;
        line-height: 56px;
        padding:     0 40px;

        @media only screen and(max-width: (@big_mobile)) {
            font-size:   14px;
            height:      36px;
            line-height: 36px;
            padding:     0 20px;
        }
    }

    &.btnSmall {
        font-size:      12px;
        letter-spacing: 0.23px;
        height:         28px;
        line-height:    28px;
        padding:        0 14px;
    }
}

/*  Wrappers    */

.btnBlock {
    display:         flex;
    flex-grow:       1;
    justify-content: center;
    align-items:     center;
    box-sizing:      border-box;
    margin-top:      25px;

    &Right {
        &:extend(.btn-block);
        justify-content: flex-end;
    }

    &Left {
        &:extend(.btn-block);
        justify-content: flex-start;
    }

    & *:not(:first-child),
    &Right *:not(:first-child),
    &Left *:not(:first-child) {
        margin-left: 20px;
    }

    @media only screen and (max-width: (@middle_mobile)) {
        justify-content: space-between;

        & *,
        &Right *,
        &Left * {
            flex: 1 0 0;
        }
    }

    &Column {
        &:extend(.btn-block);
        .flex-direction--media();

        & *:not(:first-child) {
            margin-left: 20px;
        }

        @media only screen and(max-width: (@big_mobile)) {
            margin-left:  auto;
            margin-right: auto;
            max-width:    270px;

            & * {
                width: 100%;
            }

            & *:not(:first-child) {
                margin-left: 0;
                margin-top:  10px;
            }
        }
    }
}

