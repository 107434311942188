.innerAgeBox {
    width:            150px;
    height:           180px;
    top:              60px;
    right:            auto;
    left:             10px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius:    18px;
    box-shadow:       0px 4px 8px 1px rgba(0, 0, 0, 0.1);
    backdrop-filter:  blur(10px);
    display:          flex;
    flex-direction:   column;
    justify-content:  center;
    align-items:      center;
    position:         absolute;
}

.innerAgeBoxTitle {
    font-size:     14px;
    line-height:   20px;
    font-family:   "Montserrat", sans-serif;
    color:         #ffffff;
    font-weight:   600;
    margin-bottom: 5px;
}

.innerAgeBoxNumber {
    font-size:     20px;
    line-height:   24px;
    margin-bottom: 5px;
    font-family:   "Montserrat", sans-serif;
    color:         #ffffff;
    font-weight:   600;
}

.innerAgeMeter {
    height:     80px;
    margin:     0 auto;
    overflow-y: hidden;
}

.innerAgeGauge {
    position:      relative;
    width:         120px;
    height:        120px;
    border:        8px solid #E1E4E6;
    border-radius: 50%;
    border-bottom: 8px dashed transparent;
}

.innerAgePointer {
    position:                   relative;
    top:                        -5%;
    left:                       45%;
    width:                      0;
    height:                     0;
    border-left:                2px solid transparent;
    border-right:               6px solid transparent;
    border-bottom:              60px solid #ffffff;
    border-bottom-left-radius:  35%;
    border-bottom-right-radius: 35%;
    transform-origin:           center bottom;
    z-index:                    1;
}

.innerAgeGaugeMeterStick {
    position:  absolute;
    top:       -10px;
    width:     50px;
    left:      45%;
    height:    30px;
    transform: rotate(-5deg);
}

.innerAgeText {
    color:          #ffffff;
    text-align:     center;
    font-size:      12px;
    line-height:    14px;
    letter-spacing: .03em;
    margin-top:     10px;
}

@media (min-width: 552px) {
    .innerAgeBox {
        height: 250px;
        top:    40px;
        right:  10px;
        left:   auto;
    }

    .innerAgeBoxTitle {
        font-size:   16px;
        line-height: 26px;
    }

    .innerAgeBoxNumber {
        font-size:     24px;
        line-height:   30px;
        margin-bottom: 10px;
    }

    .innerAgeText {
        font-size:   14px;
        line-height: 16px;
        margin-top:  20px;
    }
}

@media (min-width: 768px) {
    .innerAgeBox {
        width:  200px;
        height: 250px;
        right:  -40px;
        top:    100px;
    }

    .innerAgeBoxTitle {
        font-size:     24px;
        line-height:   34px;
        margin-bottom: 10px;
    }
}

@media (min-width: 840px) {
    .innerAgeBox {
        width:  250px;
        height: 300px;
        right:  -80px;
        top:    80px;
    }
}

@media (min-width: 1281px) {
    .innerAgeBox {
        width:  200px;
        height: 250px;
        right:  50px;
        top:    60px;
    }
}

@media (min-width: 1320px) {
    .innerAgeBox {
        right: 50px;
        top:   100px;
    }
}

@media (min-width: 1641px) {
    .innerAgeBox {
        right:  -80px;
        top:    70px;
        width:  250px;
        height: 300px;
    }

    .innerAgeBoxNumber {
        font-size:     56px;
        line-height:   60px;
        margin-bottom: 20px;
    }
}
