.Message {
    position:                   fixed;
    top:                        -60px;
    left:                       50%;
    transform:                  translateX(-50%);
    width:                      50vw;
    height:                     60px;

    border-bottom-left-radius:  11px;
    border-bottom-right-radius: 11px;
    display:                    flex;
    align-items:                center;
    justify-content:            center;
    z-index:                    1000;
    color:                      #000;
    font-weight:                bold;
    font-size:                  16px;
    transition:                 transform 0.8s;

    @media all and (max-width: 630px) {
        width:   70vw;
        padding: 20px;
    }

    &Show {
        transform:  translate(-50%, 60px);
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    }

    &.success {
        background-color: #bbf3bb;
    }

    &.error {
        background-color: #f5b5b5;
    }
}
