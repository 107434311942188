@import "../../../../skin/css/sg/variables/variables_less.less";

.loader {
    width:               106px;
    height:              56px;
    display:             block;
    margin:              30px auto;
    background-image:    linear-gradient(@green 50px, transparent 0), linear-gradient(@green 50px, transparent 0), linear-gradient(@green 50px, transparent 0), linear-gradient(@green 50px, transparent 0), radial-gradient(circle 14px, @green 99%, transparent 0);
    background-size:     48px 15px, 15px 35px, 15px 35px, 25px 15px, 28px 28px;
    background-position: 25px 5px, 58px 20px, 25px 17px, 2px 37px, 76px 0px;
    background-repeat:   no-repeat;
    position:            relative;
    transform:           rotate(-45deg);
    box-sizing:          border-box;

    &Container {
        width:           100%;
        height:          90vh;
        display:         flex;
        flex-direction:  column;
        justify-content: center;
        align-items:     center;
    }
}

.loader::after,
.loader::before {
    content:             "";
    position:            absolute;
    width:               56px;
    height:              56px;
    border:              6px solid @green;
    border-radius:       50%;
    left:                -45px;
    top:                 -10px;
    background-repeat:   no-repeat;
    background-image:    linear-gradient(@green 64px, transparent 0), linear-gradient(@green 66px, transparent 0), radial-gradient(circle 4px, @green 99%, transparent 0);
    background-size:     40px 1px, 1px 40px, 8px 8px;
    background-position: center center;
    box-sizing:          border-box;
    animation:           rotation 0.3s linear infinite;
}

.loader::before {
    left: 25px;
    top:  60px;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.text {
    margin-top:     32px;
    color:          @gray1;
    letter-spacing: 0.2px;
    font-size:      14px;
    line-height:    1.5;
    font-weight:    400;
}
