/**
 * INTR Style Guide Footnote
 * --------------------------------
*/
/**
 * Footnote styles
 * --------------------------------
*/
@-webkit-keyframes bgPulse {
    from {
        background-color: #C3C8CD;
    }
    50% {
        background-color: #E1E4E6;
    }
    to {
        background-color: #C3C8CD;
    }
}
@keyframes bgPulse {
    from {
        background-color: #C3C8CD;
    }
    50% {
        background-color: #E1E4E6;
    }
    to {
        background-color: #C3C8CD;
    }
}
.footnote {
    color:       #232d37;
    font-size:   12px;
    font-weight: 400;
    line-height: normal;
    padding:     10px 0 0 0;


    &Checkbox {
        display:     block;
        line-height: 20px;
    }

    &Normal {
        font-size: 14px;
    }

    &Semibold {
        font-weight: 600;
    }

    &Light {
        color: #5D676F;
    }
}
