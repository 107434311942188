.health360Container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.health360 {
  padding: 30px 0px;
  background-color: #EBF5F0;
}

.health360Wrapper {
  position: relative;
  width: 760px;
  height: 760px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.health360Wrapper::before,
.health360Wrapper::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 1;
}

.health360Wrapper::before {
  width: 656px;
  height: 656px;
  border: 0.73px solid #005550;
}

.health360Wrapper::after {
  width: 636px;
  height: 636px;
  border: 0.73px solid rgba(0, 85, 80, 0.2);
}

.health360ItemsWrap {
  width: 656px;
  height: 656px;
  position: relative;
  z-index: 2;
}

.health360Items {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 655px;
  height: 655px;
  transform: translate(-50%, -50%);
}

.health360Item {
  position: absolute;
  width: 142px;
  height: 142px;
  background-color: #EBF5F0;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: background-color .4s ease-in-out;
}

.health360ItemIcon img {
  width: 50px;
  height: 50px;
  margin-bottom: 8px;
}

.health360Content {
  width: 411px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.health360TooltipActive {
  z-index: auto;
}

.health360Title {
  font-family: "Montserrat", serif;
  font-size: 56px;
  line-height: 64px;
  font-weight: 700;
  color: #005550;
  margin: 0 0 8px;
}

.health360Desc {
  font-family: "Open Sans", serif;
  font-size: 17.48px;
  line-height: 26.2px;
  color: #005550;
  margin: 0 0 24px;
}

.health360Btn {
  padding: 10px 18px;
  background-color: #00A050;
  color: #ffffff;
  font-family: "Open Sans", serif;
  font-size: 14.56px;
  line-height: 21.8px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 2.18px;
}

.health360ItemContent .title {
  font-size: 14.16px;
  line-height: 36.6px;
  font-weight: 700;
  color: #005550;
  margin: 0;
}

.health360ItemIcon {
  width: 66px;
  height: 66px;
  background-color: #CDE6D2;
  border-radius: 50%;
  padding: 10px;
  position: relative;
}

.health360ItemIcon img {
  width: 47px;
  height: 47px;
  object-fit: cover;
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.health360ItemIcon img.noHover {
  visibility: visible;
  opacity: 1;
  transition: opacity .4s ease-in-out;
}

.health360ItemIcon img.onHover {
  visibility: hidden;
  opacity: 0;
  transition: opacity .4s ease-in-out;
}

.health360Item:hover .health360ItemIcon img.noHover {
  visibility: hidden;
  opacity: 0;
}

.health360Item:hover .health360ItemIcon img.onHover {
  visibility: visible;
  opacity: 1;
}

.health360Tooltips .tooltip {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 210px;
  min-height: 40px;
  padding: 10px 15px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(245, 245, 245, 1) 100%);
  border-radius: 10px;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.16);
  font-family: "Open Sans", serif;
  font-size: 10px;
  line-height: 15px;
  font-weight: 400;
  letter-spacing: .26px;
  color: #232D37;
  text-align: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.health360Tooltips .tooltip.visible {
  opacity: 1;
  visibility: visible;
}

.health360Tooltips .tooltip::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent rgba(255, 255, 255, 1) transparent;
  z-index: 101;
}

/* Arrow style for mobile */
.health360Tooltips .tooltipArrow {
  position: absolute;
  width: 26px;
  height: 26px;
  background: #ffffff;
  transform: translate(-50%, -50%) rotate(0deg);
  z-index: 101;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  display: none;
}

.health360Item:hover .health360ItemIcon {
  background-color: #005550;
  transition: background-color .4s ease-in-out;
}

.mobileHidden {
  display: block;
}

.desktopHidden {
  display: none;
}
.health360Tooltips.item1, .health360Tooltips.item2, .health360Tooltips.item3, .health360Tooltips.item4, .health360Tooltips.item5, .health360Tooltips.item6, .health360Tooltips.item7, .health360Tooltips.item8, .health360Tooltips.item9, .health360Tooltips.item10 {
  display: none;
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .health360Container {
    width: 100%;
    max-width: 100%;
    padding: 0px;
  }
}
@media (max-width: 768px) {
  .health360Wrapper {
    width: 370px;
    height: 370px;
    margin-top: 50px;
  }

  .health360Wrapper::before {
    width: 360px;
    height: 360px;
  }

  .health360Wrapper::after {
    width: 345px;
    height: 345px;
  }

  .health360ItemsWrap {
    width: 370px;
    height: 370px;
  }

  .health360Items {
    width: 305px;
    height: 305px;
  }

  .health360Item {
    width: 90px;
    height: 90px;
  }

  .health360ItemIcon {
    width: 42px;
    height: 42px;
    padding: 3px;
  }

  .health360ItemIcon img {
    width: 33px;
    height: 33px;
  }

  .health360Item.active .health360ItemIcon {
    background-color: #005550;
  }

  .health360Item.active .health360ItemIcon .no-hover {
    visibility: hidden;
    opacity: 0;
  }

  .health360Item.active .health360ItemIcon .onHover {
    visibility: visible;
    opacity: 1;
  }

  .health360ItemContent .title {
    font-size: 11px;
    line-height: 13px;
    font-weight: 500;
  }

  .health360Tooltips {
    width: 200px;
    height: 200px;
    padding: 20px;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.16);
    font-family: "Open Sans", serif;
    font-size: 10px;
    line-height: 15px;
    font-weight: 400;
    letter-spacing: .26px;
    color: #232D37;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .health360Tooltips p {
    margin: 0px;
  }
  
  .health360Tooltips .tooltipTitle{
    font-weight: 600;
  }

  .health360Tooltips .tooltip {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .health360Tooltips.item1, .health360Tooltips.item2, .health360Tooltips.item3, .health360Tooltips.item4, .health360Tooltips.item5, .health360Tooltips.item6, .health360Tooltips.item7, .health360Tooltips.item8, .health360Tooltips.item9, .health360Tooltips.item10 {
    display: flex;
  }

  .health360Tooltips .tooltipArrow {
    display: block;
  }

  .health360Content {
    position: inherit;
    top: inherit;
    left: inherit;
    transform: inherit;
    width: 370px;
    margin: 50px auto 0px;
  }
  .health360Title {
    font-size: 30px;
    line-height: 45px;
    padding: 0px 20px;
  }
  .health360Desc {
    font-size: 14px;
    line-height: 24px;
    padding: 0px 20px;
  }

  .mobileHidden {
    display: none;
  }

  .desktopHidden {
    display: block;
  }
}

@media (max-width: 400px) {
  .health360Wrapper {
    width: 100%;
    height: 100%;
    margin-top: 10px;
  }
  .health360ItemsWrap {
    width: 330px;
    height: 330px;
  }
  .health360Wrapper::before {
    width: 280px;
    height: 280px;
  }
  .health360Wrapper::after {
    width: 270px;
    height: 270px;
  }
  .health360Item {
    width: 60px;
    height: 60px;
  }
  .health360ItemIcon {
    width: 30px;
    height: 30px;
    padding: 3px;
  }
  .health360Title {
    font-size: 26px;
    line-height: 30px;
    padding: 0px 20px;
  }
  .health360ItemIcon img {
    width: 23px;
    height: 23px;
  }
  .health360Tooltips {
    width: 160px;
    height: 160px;
  }
  .health360Content {
    width: 300px;
  }
  .health360Tooltips .tooltipArrow {
    width: 24px;
    height: 24px;
  }
}