@import '../../../../../skin/css/sg/variables/variables_less';
@import '../../../../../skin/css/sg/mixins_less';

.footerMenuList {
    margin-right: 3.75rem;

    @media only screen and (max-width: (@big_mobile_horizontal)) {
        display: none;
    }
}

.footerTitle {
    color:          #ffffff;
    font-weight:    600;
    line-height:    1.28;
    font-size:      14px;
    letter-spacing: 0.02rem;
    margin-bottom:  5px;

    @media only screen  and (max-width: (@big_mobile_horizontal)) {
        display: none;
    }
}
