//@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,200,200italic,300,300italic,600,600italic,400italic,700,700italic&display=swap');
//@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300i,700&display=swap');
//@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i&display=swap');
//@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,300&display=swap');
//@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');

html {
    font-size:                14px;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust:     100%;
    -webkit-font-smoothing:   antialiased;
}

body {
    font-family:      "Open Sans", "Courier New", Courier, monospace;
    font-size:        100%;
    font-style:       normal;
    font-weight:      400;
    line-height:      1.4;
    color:            #464650;
    background-color: #ffffff;

}

/* reset */
header, article, footer, section, nav, aside, figure, menu, body {
    padding: 0;
    margin:  0;
    display: block; /* IE < 9 */
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Open Sans', 'Arial', 'Lucida Grande', sans-serif;
    font-weight: 500;
    margin:      1em auto 0.5em;
    color:       #232d37;
}

button {
    font-family: inherit;
}

p {
    margin-top:    0;
    margin-bottom: 0;
}

body[class*="no-scroll"] {
    overflow: hidden;
}

#app {
    min-height:     100vh;
    display:        flex;
    flex-direction: column;

    * {
        box-sizing: border-box;
    }
}
