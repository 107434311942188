/**
 * INTR Style Guide Footer React Only for responsive pages
 * --------------------------------
*/
/**
 * Footer React styles
 * --------------------------------
*/
@import "./../../styles/container";
@import "../../styles/common_less.module.less";

.footer {
    background-color: @gray1;
    padding:          35px 0;
    position:         relative;
    margin-top:       auto;

    @media only screen  and (max-width: (@big_mobile_horizontal)) {
        text-align: center;
        padding:    20px 0;
    }

    &Flex {
        display: flex;
    }

    &Title {
        color:           #ffffff;
        font-weight:     600;
        line-height:     1.28;
        font-size:       14px;
        letter-spacing:  0.02rem;
        text-decoration: none;
        margin-bottom:   5px;

        @media only screen  and (max-width: (@big_mobile_horizontal)) {
            display: none;
        }
    }

    &Contacts {
        display:        flex;
        flex-direction: column;
        margin-left:    auto;

        @media only screen  and (max-width: (@big_mobile_horizontal)) {
            margin-right: auto;
        }

        &Item {
            margin-bottom: 3px;
            display:       block;
            position:      relative;

            & + .footerContactsItem {
                margin-top: 5px;

                @media only screen  and (max-width: (@big_mobile_horizontal)) {
                    display:       inline-block;
                    padding-right: 10px;
                }
            }

            @media only screen  and (max-width: (@tablet_horizontal)) {
                font-size: 11px;
            }

            @media only screen  and (max-width: (@big_mobile_horizontal)) {
                font-size:     16px;
                margin-bottom: 0;
            }

            &Sms {
                padding-left: 12px;
                display:      none;

                &::before {
                    content:   '|';
                    position:  absolute;
                    left:      0;
                    top:       0;
                    font-size: 14px;
                    color:     @gray5;
                }

                @media only screen  and (max-width: (@big_mobile_horizontal)) {
                    display: inline-block;
                }
            }
        }

        &Block {
            margin-top: 20px;

            @media only screen  and (max-width: (@big_mobile_horizontal)) {
                font-size: 16px;
            }

            &:last-child {
                color:      @gray6;
                font-size:  12px;
                margin-top: 15px;

                @media only screen  and (max-width: (@big_mobile_horizontal)) {
                    margin-top:    20px;
                    margin-bottom: 28px;
                }
            }

            @media only screen  and (max-width: (@big_mobile_horizontal)) {
                margin-top: 19px;
            }

            &Desktop {
                @media only screen  and (max-width: (@big_mobile_horizontal)) {
                    display: none;
                }
            }

            &Mobile {
                display: none;

                @media only screen  and (max-width: (@big_mobile_horizontal)) {
                    display: block;
                }

                .footerContactsItem {
                    display:      block;
                    padding-left: 0;

                    &:before {
                        content: none;
                    }

                    &:nth-child(3) {
                        margin-top: 20px;
                    }

                    &White {
                        color: #ffffff;
                    }
                }
                .linkSecondaryLight {
                    padding-right: 0;
                }
            }
        }
    }

    &Over {
        z-index: 100;
    }
}

.contactsIcons {
    display:    flex;
    margin-top: 17px;

    @media only screen  and (max-width: (@big_mobile_horizontal)) {
        justify-content: center;
        margin-top:      10px;
    }

    &Icon {
        width:  26px;
        height: 26px;

        @media only screen  and (max-width: (@big_mobile_horizontal)) {
            width:  32px;
            height: 32px;
        }
    }

    &Wrap {
        padding-bottom: 26px;
        border-bottom:  1px solid @gray3;
        margin-bottom:  6px;

        @media only screen  and (max-width: (@big_mobile_horizontal)) {
            justify-content: center;
            order:           1;
            border-bottom:   none;
            padding-bottom:  20px;
            margin-bottom:   0;
        }
    }

    &Block {
        margin-top: 26px;
    }
}

.copyright {
    margin-top:  16px;
    color:       @gray5;
    font-size:   12px;
    line-height: 1.5;

    @media only screen  and (max-width: (@tablet)) {
        text-align: center;
        margin-top: 13px;
    }
}

.linkSecondaryLight {
    transition:      all 0.2s ease-in-out;
    cursor:          pointer;
    text-decoration: none;
    color:           @gray6;
    font-size:       1rem;
    line-height:     1.79rem;
    display:         block;

    &:hover,
    &:focus {
        color:           @gray7;
        text-decoration: underline;
    }

    &:active {
        color:           @gray4;
        text-decoration: underline;
    }

    &--disabled,
    &:disabled {
        opacity:         0.3;
        color:           @gray6;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
            opacity:         0.3;
            color:           @gray6;
            text-decoration: none;
        }
    }
}
