.ldlBox {
  position: absolute;
  left: 10px;
  top: 25%;
  padding: 10px 10px;
  background-color: rgba(255,255,255, .2);
  width: 270px;
  border-radius: 18px;
  display: none;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.1);
}

.ldlPill {
  text-align: center;
  width: 50%;
}
.ldlPill span {
  margin: 0 auto;
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #64C800;
  border-radius: 50px;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  line-height: 12px;
  font-weight: 600;
  color: #fff;
}
.ldlInfo {
  width: 50%;
  text-align: center;
}

.ldlInfo svg {
  width: 20px;
  height: 20px;
}

.ldlInfo h3 {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 14px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}

.ldlInfo p {
  font-size: 12px;
  color: #fff;
  opacity: .7;
}

.ldlGraph {
  width: calc(270px - 40px);
  height: 50px;
  margin-top: 5px;
  position: relative;
}

.ldlGraphImage {
  position: absolute;
  bottom: -4px;
  left: -6px;
  width: 100%;
  height: 100px;
  object-fit: cover;
  object-position: left;
}

.ldlGraphDot {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: rgba(255,255,255,1);
  border-radius: 100%;
}

.ldlGraphDotOne {
  top: -25%;
  left: 5%;
}

.ldlGraphLine {
  height: 3px;
  background-color: rgba(255,255,255,.8);
  position: absolute;
  border-radius: 10px;
  transform-origin: left;
}

.ldlGraphLineOne {
  width: 80px;
  transform: rotate(24deg);
  top: 4px;
  left: 5px;
}
.ldlGraphDotTwo {
  left: 37%;
  top: 40%;
}

.ldlGraphLineTwo {
  width: 70px;
  transform: rotate(4deg);
  top: 5px;
  left: 4px;
}
.ldlGraphDotThree {
  left: 68%;
  top: auto;
  bottom: 28%;
}

.ldlGraphLineThree {
  width: 50px;
  transform: rotate(-15deg);
  top: 4px;
  left: 8px;
}
.ldlGraphDotFour {
  right: 4%;
  top: 23%;
}
@media (min-width: 552px) {
  .ldlBox {
    display: flex;
  }
}
@media (min-width: 600px) {
  .ldlBox {
    top: 170px;
    left: 15px;
  }
}

@media (min-width: 768px) {
  .ldlBox {
    top: 240px;
    left: -20px;
    padding: 20px 20px;
  }
  .ldlPill span {
    width: 130px;
    height: 40px;
    font-size: 16px;
    line-height: 16px;
  }
  .ldlInfo svg {
    width: 30px;
    height: 30px;
  }
  .ldlInfo h3 {
    font-size: 20px;
    line-height: 20px;
  }
  .ldlInfo p {
    font-size: 14px;
  }
  .ldlGraph {
    margin-top: 20px;
  }
}

@media (min-width: 840px) {
  .ldlBox {
    width: 320px;
    top: 260px;
    left: -80px;
  }
  .ldlGraph {
    width: calc(320px - 40px);
  }
  .ldlGraphDotOne {
    top: -35%;
  }
  .ldlGraphLineOne {
    width: 95px;
    top: 5px;
    left: 6px;
  }
  .ldlGraphDotTwo {
    top: 45%;
  }
  .ldlGraphLineTwo {
    width: 90px;
    top: 4px;
  }
  .ldlGraphDotThree {
    bottom: 23%;
  }
  .ldlGraphLineThree {
    width: 64px;
  }
}

@media (min-width: 1281px) {
  .ldlBox {
    top: 260px;
    left: -20px;
  }
}

@media (min-width: 1641px) {
  .ldlBox {
    top: 290px;
    left: -90px;
  }
}