@import '../../../../../skin/css/sg/variables/variables_less';
@import '../../../../../skin/css/sg/mixins_less';

.footerMenuItem {
    font-size:   1rem;
    line-height: 1.79rem;
    display:     block;
}

.linkSecondary {
    transition:      all 0.2s ease-in-out;
    cursor:          pointer;
    text-decoration: none;
    color:           @gray6;

    &:hover,
    &:focus {
        color:           @gray6;
        text-decoration: underline;
    }

    &:active {
        color:           @gray4;
        text-decoration: underline;
    }

    &--disabled,
    &:disabled {
        opacity:         0.3;
        color:           @gray6;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
            opacity:         0.3;
            color:           @gray6;
            text-decoration: none;
        }
    }
}
