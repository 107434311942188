/**
 * INTR Style Guide Links React
 * --------------------------------
*/
/**
 * Links styles INTR
 * Links styles GG
 * --------------------------------
*/
@import "../../../skin/css/sg/variables/variables_less";
@import "../../../skin/css/sg/mixins_less";


.link {
    transition:       color 0.2s ease-in-out;
    cursor:           pointer;
    text-decoration:  none;
    font-size:        inherit;
    border:           none;
    outline:          none !important;
    background-color: transparent;
    padding:          0;

    &:hover,
    &:focus,
    &:active {
        text-decoration: underline;
    }

    &[disabled] {
        opacity:        0.3;
        pointer-events: none;
    }
}

.linkPrimary {
    color: @primary;

    &:hover,
    &:focus {
        color: @primary--hover;
    }

    &:active {
        color: @primary--active;
    }
}

.linkSecondary {
    color: @gray6;

    &:hover,
    &:focus {
        color: @gray6;
    }

    &:active {
        color: @gray4;
    }
}

.linkSecondaryDark {
    color:           @gray1;
    text-decoration: underline;

    &:hover,
    &:focus {
        color: @gray5;
    }

    &:active {
        color: @gray4;
    }
}

.linkSecondaryLightDecoration {
    color:           @gray5;
    text-decoration: underline !important;

    &:hover,
    &:focus {
        color: @gray4;
    }

    &:active {
        color: @gray6;
    }
}

.linkSecondaryLight {
    color: @gray6;

    &:hover,
    &:focus {
        color: @gray7;
    }

    &:active {
        color: @gray4;
    }
}

.linkSemibold {
    font-weight:    600;
    letter-spacing: 0.14px;
}

.linkCentered {
    text-align: center;
}
