.themeStepCard {
  min-width: 240px;
  max-width: 240px;
  min-height: 100%;
  border-radius: 15px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.06);
  text-align: left;
  position: relative;
}

.themeStepCardTag {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  padding: 6px 12px;
  background-color: #64C800;
  color: #fff;
  border-radius: 15px 0 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
}

.themeStepCardMedia {
  max-height: 150px;
  min-height: 150px;
  max-width: 240px;
  border-radius: 15px 15px 0 0;
}

.themeStepCardMedia img {
  max-height: 150px;
  height: 150px;
  max-width: 240px;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
}

.themeStepCardInfo {
  padding: 20px 20px 30px;
  text-align: left;
}

.themeStepCardStep {
  width: 60px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 17px;
  background-color: #005550;
  font-size: 10px;
  line-height: 20px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  margin-bottom: 5px;
  color: #FFFFFF;
  margin-right: auto;
}

.themeStepCardTitle {
  font-size: 14px;
  line-height: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin-bottom: 5px;
  color: #005550;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.themeStepCardInfoText {
  font-size: 12px;
  line-height: 15px;
  color: #005550;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
}

@media (min-width: 640px) {
  .themeStepCard {
    min-width: 400px;
    max-width: 400px;
    min-height: 100%;
    border-radius: 25px;
  }
  
  .themeStepCardMedia {
    max-height: 240px;
    min-height: 240px;
    max-width: 400px;
    border-radius: 25px 25px 0 0;
  }
  
  .themeStepCardMedia img {
    max-height: 240px;
    max-width: 400px;
    height: 240px;
    border-radius: 25px 25px 0 0;
  }
  
  .themeStepCardInfo {
    padding: 30px 40px 40px;
  }
  
  .themeStepCardStep {
    width: 110px;
    height: 40px;
    border-radius: 22px;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 10px;
  }
  
  .themeStepCardTitle {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  
  .themeStepCardInfoText {
    font-size: 20px;
    line-height: 25px;
  }
}

@media (min-width: 992px) {
  .themeStepCard {
    min-width: 300px;
    max-width: 300px;
    min-height: 100%;
    border-radius: 20px;
  }
  
  .themeStepCardMedia {
    max-height: 190px;
    min-height: 190px;
    max-width: 300px;
    border-radius: 20px 20px 0 0;
  }
  
  .themeStepCardMedia img {
    max-height: 190px;
    height: 190px;
    max-width: 300px;
    border-radius: 20px 20px 0 0;
  }
  
  .themeStepCardInfo {
    padding: 25px 30px 40px;
  }
  
  .themeStepCardStep {
    width: 80px;
    height: 30px;
    border-radius: 17px;
    font-size: 14px;
    line-height: 24px;
  }
  
  .themeStepCardTitle {
    font-size: 18px;
    line-height: 18px;
  }
  
  .themeStepCardInfoText {
    font-size: 15px;
    line-height: 20px;
  }
}