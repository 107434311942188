@import "../../../styles/container";
@import "../../../styles/page";
@import "../../../styles/hide-show";
@import "../../../styles/statement";
@import "../../../styles/section";
@import "../../../styles/text";
@import "../../../styles/paper_blurb";
@import "../../../styles/links";
@import "../../../styles/icons";

.Home {
    font-family: 'Montserrat', 'Open Sans', Courier, monospace;
    overflow:    hidden;
}

.mobileBtn() {
    width:          335px;
    height:         40px;
    font-size:      16px;
    font-weight:    600;
    font-style:     normal;
    letter-spacing: 0.3px;
    line-height:    40px;
}

.hideTablet {
    display: block !important;

    @media all and (max-width: (@tablet)) {
        display: none !important;
    }
}

.showTablet {
    display: none !important;

    @media all and (max-width: (@tablet)) {
        display: block !important;
    }
}

// first section
.homeImageTop {
    height:              594px !important;
    background-position: 100% 0;
    background-repeat:   no-repeat;
    background-size:     cover;
    position:            relative;

    @media all and (max-width: (@big_mobile)) {
        height:              460px !important;
        background-position: 25% 0;
    }

    &WithInt {
        @media all and (max-width: (@mobile)) {
            height: 644px !important;
        }
    }

    &WithReferral {
        height: 640px !important;
    }

    .homeImageTopTitle {
        margin-top: 0;
        text-align: center;
        font-size:  96px;

        @media all and (max-width: (@big_tablet)) {
            font-size:      55px;
            line-height:    55px;
            letter-spacing: 0.2px;
        }

        @media all and (max-width: (@big_mobile)) {
            margin-top:  76px;
            font-size:   45px;
            line-height: 45px;
            padding:     0 16px;
        }
    }

    .homeImageTopCaption {
        color:       #ffffff;
        font-size:   33px;
        left:        0;
        line-height: 1.3;
        text-align:  center;
        margin:      8px auto 45px;

        @media all and (max-width: (@big_mobile)) {
            font-size:    20px;
            line-height:  1.3;
            max-width:    none;
            margin-left:  auto;
            margin-right: auto;
            margin-top:   20px;
            padding:      0 16px;
            top:          44%;
            position:     static;
        }
    }

    .homeImageTopItem {
        display:         flex;
        align-items:     center;
        justify-content: center;
        flex-direction:  column;
        height:          100%;

        @media all and (max-width: (@big_mobile)) {
            justify-content: flex-start;
        }
    }

    a {
        @media all and (max-width: (@big_mobile)) {
            font-size:   16px;
            height:      48px;
            line-height: 48px;
        }
    }
}

.homezone {
    overflow: hidden;

    &Text {
        padding: 120px 0 60px;

        @media only screen and (max-width: (@big_tablet)) {
            text-align: center;
            padding:    72px 0 60px;
        }

        &Title {
            letter-spacing: -1px;
        }

        &Description {
            line-height: 30px;
            margin:      17.5px 0;

            @media only screen and (max-width: (@mobile)) {
                line-height:   24px;
                margin-bottom: 15px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &White {
                color: #ffffff;
            }

            &Green {
                color: @green1;
            }
        }

        &Short {
            max-width: 49%;

            @media only screen and (max-width: (@big_tablet)) {
                max-width: none;
            }
        }

        &Center {
            text-align: center;
        }
    }
}

// Ask questions section
.homezoneFaq {
    background-color: @gray8;

    &Wrapper {
        display:         flex;
        align-items:     center;
        justify-content: space-between;

        @media all and (max-width: (@big_tablet_horizontal)) {
            flex-direction: column;
        }
    }

    &Text {
        width: 100%;

        @media all and (max-width: (@big_tablet_horizontal)) {
            order: 2;
        }
    }

    .homezoneTextTitle {
        line-height:   1.2;
        margin-bottom: 0;
    }

    .homezoneTextDescription {
        font-size: 24px;
    }

    .homezoneTextShort {
        max-width:      100%;
        padding-top:    48px;
        padding-bottom: 38px;

        @media all and (max-width: (@tablet)) {
            padding-top: 20px;
        }
    }

    .rightBtnContainer {
        margin-bottom: 80px;

        @media all and (max-width: (@tablet)) {
            flex-direction: column;
            align-items:    center;
            margin-bottom:  50px;
        }
    }

    &Btn {
        margin-left: 20px !important;

        @media all and (max-width: (@tablet)) {
            margin-left:   0 !important;
            margin-bottom: 20px;
            width:         185px;
        }

        @media all and (max-width: (@big_mobile)) {
            width: 205px;
        }

        &:first-child {
            margin-left: 0 !important;
        }

        &Icon {
            fill:         #ffffff;
            width:        22px;
            min-width:    22px;
            height:       22px;
            margin-right: 10px;

            &:first-child {
                width:     24px;
                min-width: 24px;
                height:    24px;
            }

            &Wrap {
                display:     flex;
                align-items: center;
            }
        }
    }

    &ImgContainer {
        display:         flex;
        align-items:     center;
        justify-content: center;
        width:           114%;
        margin-right:    -10%;

        @media all and (max-width: (@big_tablet_horizontal)) {
            margin-right: 0;
            margin-top:   50px;
        }
    }

    &Img {
        width: 100%;

        @media all and (max-width: (@big_tablet_horizontal)) {
            width: 70%;
        }

        @media all and (max-width: (@big_mobile)) {
            width: 100%;
        }
    }
}

.homezoneScience {
    background-repeat:     no-repeat;
    background-color:      @gray8;
    background-size:       cover;
    background-position-x: center;

    @media only screen and (min-width: (2000px)) and (max-width: (4500px)) {
        background-size:  contain;
        background-color: @gray9;
    }

    @media only screen and (max-width: (@desktop)) {
        background-position: 60% 100%;
    }

    @media only screen and (max-width: (960px)) {
        background-position: 70% 100%;
    }

    @media only screen and (max-width: (@big_tablet)) {
        background-position: 50% 51%;
        padding-top:         315px;
    }

    @media only screen and (max-width: (@mobile)) {
        background-position: 50% bottom;
        background-size:     contain;
        padding-top:         325px;
    }

    .homezoneText {
        width:       100%;
        max-width:   473px;
        margin-left: auto;

        @media only screen and (max-width: (@big_tablet)) {
            max-width:      none;
            padding-bottom: 40px;
        }

        &Btn {
            margin-top: 25px;

            @media only screen and (max-width: (@mobile)) {
                margin-top: 10px;
            }
        }
    }

    .homezoneTextTitle {
        color: #ffffff;
    }
}

// Get started section
.homezoneSteps {
    padding: 44px 0 0 !important;

    &Title {
        color: @green1 !important;
    }

    &Subtitle {
        color:         @green1 !important;
        font-size:     24px !important;
        margin-bottom: 0 !important;
    }

    &StepTitle {
        color:     @green1;
        font-size: 20px !important;
    }

    &StepCount {
        color:          @green1;
        text-transform: capitalize !important;
        font-size:      20px !important;
    }

    &Wrap {
        margin-top: 31px !important;

        @media only screen and (max-width: (@big_mobile)) {
            margin-bottom: 50px !important;
        }
    }

    &IconWrap {
        height: 206px !important;

        @media only screen and (max-width: (@big_mobile)) {
            height: 160px !important;
        }
    }

    &Button {
        @media all and (max-width: (@big_mobile)) {
            margin-top: 24px;
        }
    }

    .homezoneStep1,
    .homezoneStep2,
    .homezoneStep3,
    .homezoneStep4 {
        height:    206px;
        max-width: 206px;

        @media only screen and (max-width: (@big_mobile)) {
            width:         auto;
            height:        160px;
            margin-bottom: 0;
        }
    }
}

// Start inside section
.homezoneSelfie {
    background-repeat:   no-repeat;
    background-size:     cover;
    background-position: 50% center;
    position:            relative;

    @media all and (max-width: @big_tablet_horizontal) {
        display:         flex;
        flex-direction:  column;
        justify-content: center;
    }

    .containerFlex {
        display:     flex;
        align-items: center;

        @media all and (max-width: @big_tablet_horizontal) {
            flex-direction:  column;
            justify-content: center;
            order:           2;
        }
    }

    @media only screen and (max-width: (@big_mobile)) {
        background-position: 50% 0;
    }

    .rightBtnContainer {
        margin-top: 50px;

        @media only screen and (max-width: 1217px) {
            margin-bottom: 80px;
        }

        @media only screen and (max-width: 1150px) {
            margin-bottom: 50px;
        }

        @media only screen and (max-width: @big_tablet_horizontal) {
            justify-content: center;
            margin-bottom:   60px;
        }
    }

    .homezoneText {
        width:          100%;
        max-width:      627px;
        padding-top:    110px;
        padding-bottom: 0;

        @media only screen and (max-width: 1370px) {
            max-width:   560px;
            padding-top: 80px;
        }

        @media only screen and (max-width: 1150px) {
            max-width:   500px;
            padding-top: 70px;
        }

        @media only screen and (max-width: @big_tablet_horizontal) {
            max-width:      none;
            text-align:     center;
            padding-top:    30px;
            padding-bottom: 50px;
        }

        @media only screen and (max-width: @big_mobile) {
            padding-bottom: 0;
        }

        &Title {
            margin-bottom: 15px;
        }

        &Description {
            max-width: 95%;
            font-size: 24px;

            @media only screen and (max-width: (@big_tablet_horizontal)) {
                max-width: none;
            }
        }
    }

    &ImgContainer {
        position:  absolute;
        top:       0;
        right:     0;
        width:     100%;
        max-width: 700px;

        @media only screen and (max-width: 1217px) {
            max-width: 650px;
        }

        @media only screen and (max-width: 1150px) {
            max-width: 640px;
        }

        @media only screen and (max-width: 1100px) {
            margin-right: -14%;
        }

        @media only screen and (max-width: @big_tablet_horizontal) {
            position:     static;
            margin-right: 0;
            margin-left:  auto;
        }

        @media only screen and (max-width: @big_mobile) {
            max-width: 480px;
        }
    }

    &Img {
        width: 100%;
    }
}

// InnerAge section
.homezoneInnerAge {
    background-repeat:   no-repeat;
    background-size:     cover;
    background-position: 55% center;

    @media only screen and (max-width: (@tablet)) {
        background-position: 65% center;
    }

    @media only screen and (max-width: (@big_mobile)) {
        background-position: 40% 0;
    }

    &Text {
        font-size:     45px;
        color:         #ffffff;
        margin-bottom: 30px;

        @media only screen and (max-width: (@big_mobile)) {
            margin-bottom: 0;
        }
    }

    .homezoneText {
        max-width:   64%;
        padding-top: 68px;

        @media only screen and (max-width: (@big_tablet)) {
            max-width: none;
        }

        @media only screen and (max-width: (@big_mobile)) {
            margin-bottom:  0;
            padding-top:    100%;
            padding-bottom: 50px;
        }

        @media only screen and (max-width: 376px) {
            padding-top: 120%;
        }

        &Title {
            margin-bottom: 15px;
        }

        &Description {
            max-width: 95%;
            font-size: 24px;

            @media only screen and (max-width: (@big_tablet)) {
                max-width: none;
            }
        }
    }

    .rightBtnContainer {
        margin-bottom: 80px;

        @media only screen and (max-width: (@big_tablet)) {
            justify-content: center;
            margin-bottom:   50px;
        }
    }
}

// “Normal” is not optimal section
.homezoneAnalysis {
    background-color: @gray8;

    .containerFlex {
        display:         flex;
        align-items:     center;
        justify-content: space-between;

        @media all and (max-width: @big_tablet_horizontal) {
            flex-direction: column;
        }
    }

    .homezoneTextTitle {
        line-height:   1.2;
        color:         #ffffff;
        margin-bottom: 10px;
    }

    .homezoneTextDescription {
        font-size:     24px;
        color:         #ffffff;
        margin-bottom: 45px;
        margin-top:    0;
    }

    .homezoneTextShort {
        margin-left:    90px;
        padding-bottom: 162px;

        @media all and (max-width: @big_tablet_horizontal) {
            margin-left:    0;
            padding-top:    20px;
            padding-bottom: 50px;
        }
    }

    &ImgContainer {
        display:         flex;
        align-items:     center;
        justify-content: center;
        width:           114%;

        @media all and (max-width: @big_tablet_horizontal) {
            width:      100%;
            margin-top: 50px;
        }
    }

    &Img {
        width: 100%;

        @media all and (max-width: @big_tablet_horizontal) {
            width: 60%;
        }

        @media all and (max-width: @big_mobile) {
            width: 100%;
        }
    }
}

// Stories section
.homezoneReview {
    background: #ffffff;

    .homezoneText {
        padding-top: 53px;

        &Title {
            color: @green1;
        }

        @media only screen and (max-width: (@big_tablet)) {
            padding-bottom: 60px;
        }
    }

    .review {
        display:         flex;
        flex-wrap:       wrap;
        justify-content: center;
        margin:          90px -25px 22px;

        @media only screen and (max-width: (@mobile)) {
            margin: 55px -25px 2px;
        }

        &Item {
            display:    flex;
            box-sizing: border-box;
            text-align: left;
            width:      ~"calc(50% - 68px)";
            margin:     0 26px 30px;

            @media only screen and (max-width: (@big_tablet)) {
                display:       block;
                width:         auto;
                text-align:    center;
                margin-bottom: 63px;

                &:last-child {
                    margin-bottom: 30px;
                }
            }
        }

        &Description {
            text-align: center;
            width:      100%;
            max-width:  460px;
            margin:     0 auto;
            position:   relative;
            z-index:    1;

            &::before {
                content:     "“";
                position:    absolute;
                top:         -102px;
                left:        50%;
                transform:   translateX(-50%);
                font-size:   200px;
                color:       @gray7;
                font-weight: 700;
                z-index:     -1;

                @media only screen and (max-width: (@big_tablet)) {
                    font-size: 165px;
                    top:       -83px;
                }
            }
        }

        &Cite {
            font-size:   24px;
            color:       @gray1;
            line-height: 1.5;

            @media only screen and (max-width: (@big_tablet)) {
                font-size: 20px;
            }
        }

        &Author {
            font-size:      16px;
            font-weight:    700;
            text-transform: uppercase;
            margin-top:     14px;
            color:          @gray1;
        }

        &Job {
            font-weight:    600;
            font-size:      14px;
            text-transform: none;
            color:          @gray1;
        }
    }
}

// Human minds section
.homezoneGet {
    background: @gray8;

    &Wrapper {
        display:         flex;
        align-items:     center;
        justify-content: space-between;

        @media all and (max-width: @big_tablet_horizontal) {
            flex-direction: column;
            padding-bottom: 60px;
        }
    }

    &Text {
        width: 109%;

        @media all and (max-width: @big_tablet_horizontal) {
            order: 2;
            width: 100%;
        }
    }

    .homezoneTextTitle {
        line-height: 1.2;
    }

    .homezoneTextDescription {
        font-size:     24px;
        margin-bottom: 60px;
    }

    .homezoneTextShort {
        max-width: 100%;
        padding:   0;
    }

    &ImgContainer {
        display:         flex;
        align-items:     center;
        justify-content: center;
        width:           113%;
        padding-top:     24px;
        padding-bottom:  34px;

        @media all and (max-width: @big_tablet_horizontal) {
            width: 100%;
        }
    }

    &Img {
        width: 100%;

        @media all and (max-width: @big_tablet_horizontal) {
            width: 50%;
        }

        @media all and (max-width: @big_mobile) {
            width: 90%;
        }
    }

    .btnContainer {
        @media all and (max-width: @big_tablet_horizontal) {
            display:         flex;
            justify-content: center;
        }
    }
}

// Partners section
.homezonePartners {
    background-color: @gray8;

    &Inner {
        display:         flex;
        flex-direction:  row-reverse;
        justify-content: space-between;

        > * {
            width: 48%;
        }

        @media all and (max-width: (@big_tablet)) {
            flex-direction:  column;
            justify-content: center;
            align-items:     center;

            > * {
                width: 100%;
            }
        }
    }

    &ImgContainer {
        display:         flex;
        align-items:     center;
        justify-content: center;

        @media only screen and (max-width: (@big_tablet)) {
            order:       1;
            padding-top: 50px;
        }
    }

    &Img {
        width: 100%;

        @media only screen and (max-width: (@big_tablet)) {
            width: 80%;
        }

        @media only screen and (max-width: (@mobile)) {
            width: 100%;
        }
    }

    .homezoneText {
        padding-bottom: 81px;
        padding-top:    69px;

        @media only screen and (max-width: (@big_tablet)) {
            order:          2;
            padding-top:    40px;
            padding-bottom: 50px;
        }

        &Description {
            font-size: 24px;

            @media only screen and (max-width: (@big_tablet)) {
                color: @green1;
            }
        }

        &Title {
            @media only screen and (max-width: (@big_tablet)) {
                color: @green1;
            }
        }
    }

    .partners {
        width: 80%;

        @media only screen and (max-width: (@big_tablet)) {
            width: 100%;
        }
    }

    .partnersWrapper {
        margin-top:      63px;
        margin-bottom:   57px;
        display:         flex;
        flex-wrap:       wrap;
        align-items:     center;
        justify-content: center;

        @media only screen and (max-width: (@mobile)) {
            margin-top:    18px;
            margin-bottom: 14px;
        }
    }

    .partnersItem {
        vertical-align:      top;
        display:             inline-block;
        margin:              0 22px;
        cursor:              pointer;
        width:               150px;
        height:              64px;
        background-position: 0 50%;
        background-repeat:   no-repeat;
        position:            relative;

        svg {
            position: absolute;
            width:    100%;
            height:   100%;
            left:     0;
            top:      0;
        }

        &Discovery {
            width: 200px;
        }

        .svgRgb {
            transition: all 0.1s ease-in;
            opacity:    0;
        }

        &:hover {
            .svgRgb {
                opacity: 1;
            }
        }

        @media only screen and (max-width: (@middle_mobile)) {
            width:  122px;
            margin: 2% 10px;
        }
    }
}

// don't use for now
.SignUpSection {

    background-position: right;

    &Button {
        display:   block !important;
        margin:    30px auto 0 !important;
        max-width: 124px;
    }

    &Success {
        display:     flex;
        align-items: center;

        span {
            @media only screen and (max-width: (@tablet)) {
                font-size: 16px;
            }
        }
    }
}

// topBanner don't use for now
.topBanner {
    &Animate {
        animation: FadeOutIn 4s infinite;
    }
}

/* classes for referral banner begin */
.referralBannerShow {
    header {
        top: 115px;

        @media only screen and (max-width: (@tablet_horizontal)) {
            top: 156px;
        }

        @media only screen and (max-width: (@big_mobile)) {
            top: 195px;
        }
    }

    .homeImageTop {
        padding-top: 115px !important;
        height:      640px !important;

        @media all and (max-width: (@big_mobile)) {
            padding-top: 195px !important;
            height:      660px !important;
        }
    }
}

.referralBannerShow.intBannerShow {
    header {
        top: 190px;

        @media only screen and (max-width: (@tablet_horizontal)) {
            top: 241px;
        }

        @media only screen and (max-width: (@big_mobile)) {
            top: 280px;
        }

        @media only screen and (max-width: (@mobile)) {
            top: 319px;
        }

        nav {

            @media only screen and (max-width: (@tablet_horizontal)) {
                padding-bottom: 241px !important;
            }

            @media only screen and (max-width: (@big_mobile)) {
                padding-bottom: 280px !important;
            }

            @media only screen and (max-width: (@mobile)) {
                padding-bottom: 319px !important;
            }
        }
    }

    .referralBanner {
        top: 75px;

        @media only screen and (max-width: (@tablet_horizontal)) {
            top: 85px;
        }

        @media only screen and (max-width: (@mobile)) {
            top: 124px;
        }
    }

    .homeImageTop {
        padding-top: 190px !important;
        height:      730px !important;

        @media all and (max-width: (@big_mobile)) {
            padding-top: 280px !important;
            height:      750px !important;
        }
    }
}

/* classes for referral banner end*/

@keyframes FadeOutIn {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.intBanner {
    height:      75px;
    position:    fixed;
    top:         -130px;
    transition:  top 1s linear;
    left:        0;
    width:       100%;
    z-index:     100;
    background:  #ffffff;
    display:     flex;
    align-items: center;

    @media only screen and (max-width: (@tablet_horizontal)) {
        height: 85px;
    }

    @media only screen and (max-width: (@mobile)) {
        height: 124px;
    }

    &Show {
        top: 0;
    }

    &Text {
        margin:         0;
        letter-spacing: 0.16px;
        padding-left:   26px;
        padding-right:  15px;
        position:       relative;

        @media only screen and (max-width: (@mobile)) {
            padding-left:  0;
            padding-right: 0;
            text-align:    center;
        }
    }

    &Icon {
        width:  105px;
        height: 67px;
    }

    .linkPrimary {
        padding-left: 5px;
    }

    &Container {
        position:    relative;
        display:     flex;
        align-items: center;
    }

    &Close {
        right:         -10px;
        top:           50%;
        transform:     translateY(-50%);
        width:         44px;
        height:        44px;
        line-height:   44px;
        border-radius: 50%;

        svg {
            width:  10px;
            height: 10px;
        }

        @media only screen and (max-width: (@mobile)) {
            right:     3px;
            width:     34px;
            height:    34px;
            transform: translateY(0);
        }
    }
}

.multiStepForm {
    height:     720px;
    border:     none;
    opacity:    1;
    transition: opacity 0.8s;

    @media all and (max-width: @tablet) {
        height: 700px;
    }

    &Wrapper {
        position:        relative;
        display:         flex;
        justify-content: center;
        height:          663px;
        margin-top:      23px;
        margin-bottom:   10px;
        //background-color:  #e1e4e6;
        //background-image:  linear-gradient(90deg, @gray7, @gray7 17%, #fff 20%, #fff 80%, @gray7 83%);
        //background-repeat: no-repeat;
        //animation:         loading2 3s infinite 1s;

        @media all and (max-width: @tablet) {
            height: 716px;
        }

        @media all and (max-width: 380px) {
            height: 680px;
        }

        @media all and (max-width: @mobile) {
            margin-bottom: 0;
        }
    }

    &Loading {
        opacity: 0;
    }

    &Spinner {
        position:         absolute;
        display:          none;
        height:           560px;
        justify-content:  center;
        align-items:      center;
        flex-direction:   column;
        width:            99%;
        max-width:        1100px;
        border-radius:    16px;
        margin:           50px auto 55px;
        background-color: @gray7;
        opacity:          0;
        transition:       opacity 0.8s;
        overflow:         hidden;
        padding:          30px 0;

        p {
            height:           65px;
            background-color: @gray6;
            border-radius:    6px;
            width:            70%;

            @media screen and (max-width: (@tablet)) {
                height: 45px;
            }

            &:nth-child(2n) {
                width:      80%;
                margin-top: 4px;
            }
        }

        span {
            height:           44px;
            background-color: @gray6;
            border-radius:    6px;
            width:            70%;
            margin-top:       15px;

            @media screen and (max-width: (@tablet)) {
                height:     35px;
                margin-top: 50px;
            }
        }

        div {
            height:           58px;
            background-color: @gray6;
            border-radius:    6px;
            width:            204px;
            margin-top:       2em;

            @media screen and (max-width: (@tablet)) {
                width:  90%;
                margin: 2em auto;
            }
        }


        &Show {
            display: flex;
            opacity: 1;
        }

        &::before {
            content:          "";
            opacity:          0.8;
            position:         absolute;
            height:           100%;
            width:            80%;
            background-color: #fff;
            transform:        translateX(-107%);
            top:              0;
            box-shadow:       0 0 20px 20px #fff;
            animation:        loading 3s infinite 1s;
        }

        @media screen and (max-width: (@tablet)) {
            margin: 15px auto 30px;
        }

        @media all and(max-width: 600px) {
            width:           97%;
            height:          616px;
            justify-content: flex-start;
            padding-top:     120px;
        }
    }
}

@keyframes loading {
    from {
        transform: translateX(-107%);
    }
    to {
        transform: translateX(125%);
    }
}

.centeredBtnContainer {
    display:         flex;
    justify-content: center;
    margin-bottom:   130px;
}

.rightBtnContainer {
    display:         flex;
    justify-content: flex-start;
    margin-bottom:   130px;
}
