@import "../../skin/css/sg/variables/variables_less";
@import "../../skin/css/sg/mixins_less";

.text24 {
    margin:         0 0 24px 0;
    color:          @gray1;
    letter-spacing: 0.3px;
    font-size:      24px;
    line-height:    1.5;
    font-weight:    400;

    @media only screen and (max-width: (@tablet)) {
        font-size: 20px;
    }
}

.text20const {
    margin:         0 0 24px 0;
    color:          @gray1;
    letter-spacing: 0.3px;
    font-size:      20px;
    line-height:    1.5;
    font-weight:    400;
}

.text16const {
    margin:         0 0 24px 0;
    color:          @gray1;
    letter-spacing: 0.3px;
    font-size:      16px;
    line-height:    1.5;
    font-weight:    400;
}

.text20 {
    .text20const();

    @media only screen and (max-width: (@tablet)) {
        font-size: 16px;
    }
}

.text16 {
    .text16const();

    @media only screen and (max-width: (@tablet)) {
        font-size: 14px;
    }
}

.text14 {
    margin:         0 0 24px 0;
    color:          @gray1;
    letter-spacing: 0.3px;
    font-size:      14px;
    line-height:    1.5;
    font-weight:    400;
}

.text12 {
    margin:         0 0 24px 0;
    color:          @gray1;
    letter-spacing: 0.3px;
    font-size:      12px;
    line-height:    16px;
    font-weight:    400;

    //new footnote
    &Secondary {
        font-size: 12px;
        color:     @gray5;
    }
}

.text10 {
    color:          @gray1;
    font-size:      10px;
    font-weight:    400;
    font-style:     normal;
    letter-spacing: 0.3px;
    text-align:     left;
    line-height:    14px;
}

.textWhite {
    color: #ffffff;
}

.textSemibold {
    font-weight: 600;
}

.textBold {
    font-weight: 700;
}


