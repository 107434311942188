@import "../../skin/css/sg/variables/variables_less";
@import "../../skin/css/sg/mixins_less";

.subHeader {
    box-sizing:    border-box;
    background:    #ffffff;
    height:        60px;
    border-bottom: solid 1px @gray7;

    &Wrapper {
        display:         flex;
        justify-content: space-between;
        align-items:     center;
        height:          100%;

        &FlexEnd {
            justify-content: flex-end;
        }
    }

    &Status {
        max-height: 20px;

        .subHeaderText {
            &Danger {
                color: @danger;
            }

            &:hover,
            &:focus {
                color:           @gray5;
                text-decoration: inherit;
            }
        }

        .subHeaderData {
            font-size:      14px;
            color:          @gray1;
            font-weight:    400;
            letter-spacing: 0.12px;
            padding-left:   4px;
        }
    }

    &Links {
        margin-left: auto;
    }

    &LinksItem {
        transition:      all 0.2s ease-in-out;
        cursor:          pointer;
        text-decoration: none;
        font-size:       inherit;
        padding:         0;
        border:          none;
        background:      none;
        outline:         none;
    }

    &LinksItem,
    &Text {
        font-size:      12px;
        font-weight:    400;
        line-height:    1.5;
        color:          @gray5;
        letter-spacing: 0.12px;
        padding-left:   16px;

        &:hover,
        &:focus {
            color:           @gray4;
            text-decoration: underline;
        }

        &:active {
            color:           @gray4;
            text-decoration: underline;
        }
    }

    .cardHover[data-hover="info-popup"] .infoPopupWindow,
    .infoPopup .infoPopupWindow {
        top: 23px;
    }
}

.subHeaderHero {
    box-sizing: border-box;
    width:      100%;
    text-align: center;
    color:      @gray1;
    background: #ffffff;
    margin:     0 auto 71px;

    @media only screen and (max-width: (@big_mobile)) {
        margin-bottom: 15px;
    }

    &Beta {
        text-transform:   uppercase;
        background-color: @special;
        color:            #ffffff;
        padding:          2px 9px;
        letter-spacing:   0.29px;
        border-radius:    10px;
        font-size:        10px;
        font-weight:      700;
    }

    &Title {
        color:          @gray1;
        font-size:      30px;
        font-weight:    600;
        line-height:    1.8;
        letter-spacing: 0.6px;
        display:        block;
        margin:         53px auto 18px;

        @media only screen and (max-width: (@big_mobile)) {
            margin:    25px auto 5px;
            font-size: 26px;
        }
        @media only screen and (max-width: (@mobile)) {
            font-size:     24px;
            line-height:   32px;
            margin-bottom: 15px;
        }
    }

    &Text {
        font-size:      16px;
        font-weight:    400;
        line-height:    1.5;
        letter-spacing: 0.16px;
        @media only screen and (max-width: (@mobile)) {
            font-size: 14px;
        }

        &Last {
            margin-top: 20px;
        }
    }
}

.subHeaderBtnBlock {
    font-size:  16px;
    padding:    0 8px;
    margin-top: 28px;

    .link,
    .link-primary {
        font-size: 16px;
        padding:   0 16px;
    }
}
