.teamCard {
  background-color: #fff;
  min-height: 370px;
  max-width: 240px;
  width: 240px;
  border-radius: 15px;
  border: 1px solid #fff;
  box-shadow: 0px 0px 39px rgba(0, 0, 0, 0.14)
}
.teamCardImage {
  min-height: 230px;
  max-height: 230px;
  max-width: 100%;
  border-radius: 15px 15px 0 0;
  position: relative;
}
.teamCardImage img {
  max-width: inherit;
  max-height: inherit;
  border-radius: 15px 15px 0 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.teamCardInfo {
  border-radius: 0 0 15px 15px;
}

.teamCardName {
  text-align: center;
  font-size: 14px;
  line-height: 15px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: var(--title-color);
  padding: 0px 15px 0px 15px;
  background-color: var(--title-bg-color);
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.teamCardTitle {
  font-size: 12px;
  line-height: 14px;
  font-family: 'Open Sans', sans-serif;
  color: var(--team-content-color);
  text-align: center;
  margin-top: 10px;
  max-width: 170px;
  margin-left: auto;
  margin-right: auto;
}

.teamCardLink {
  display: block;
  margin-top: 45px;
  color: var(--team-button-color);
  font-weight: 700;
  text-decoration: underline;
  margin-bottom: 20px;
}
@media (min-width: 552px) {
  .teamCard {
    min-height: 460px;
    max-width: 300px;
    width: 300px;
    border-radius: 20px;
  }
  .teamCardImage {
    min-height: 270px;
    max-height: 270px;
    border-radius: 20px 20px 0 0;
  }
  .teamCardImage img {
    border-radius: 20px 20px 0 0;
  }
  .teamCardInfo {
    border-radius: 0 0 20px 20px;
  }
  .teamCardName {
    font-size: 18px;
    line-height: 19px;
    padding: 0px 10px;
  }
  .teamCardTitle {
    font-size: 14px;
    line-height: 18px;
    max-width: 215px;
    margin-top: 15px;
  }
}