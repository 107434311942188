@import '../../../../../skin/css/sg/variables/variables_less';
@import '../../../../../skin/css/sg/mixins_less';

.logoLink {
    margin:      2px 4.175rem 0 0;
    height:      @col_top_height;
    line-height: @col_top_height;
    display:     inline-block;
    width:       12.21rem;
    box-sizing:  border-box;

    @media only screen and (max-width: (@max_width - @infelicity)) {
        margin: 0 2rem 0 0;
    }

    @media only screen and (max-width: (@max_width - @infelicity*2)) {
        margin: 0 1rem 0 0;
    }

    @media only screen and (max-width: (@desktop)) {
        width: 11rem;
    }

    @media only screen and (max-width: (@big_width)) {
        width:     14rem;
        margin:    0 auto;
        position:  absolute;
        left:      50%;
        transform: translateX(-50%);
    }
}

.logoLinkIcon {
    box-sizing:     border-box;
    position:       relative;
    z-index:        1;
    vertical-align: text-bottom;
    height:         @col_top_height;
    line-height:    @col_top_height;
    width:          100%;
    display:        block;
    top:            -4px;
    padding-bottom: 6px;

    @media only screen and (max-width: (@tablet_horizontal)) {
        height:         60px;
        padding-bottom: 4px;
        top:            0;
    }
}
