@import "../../skin/css/sg/variables/variables_less";

.page {
    padding: 69px 0 0;

    @media screen and (max-width: @big_width) {
        padding-top: 60px;
    }
}

/* for ticket QS-18734 */
body {
    position: relative;
}
.smcx-widget.smcx-embed.smcx-show {
    position: fixed !important;
    bottom: 0;
}