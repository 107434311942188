@import '../../../../../../skin/css/sg/variables/variables_less';
@import '../../../../../../skin/css/sg/mixins_less';

.navlink {
    display:         inline-block;
    vertical-align:  top;
    font-size:       1.12rem;
    transition:      all 0.2s ease-in-out;
    cursor:          pointer;
    padding:         0 0.9rem;
    letter-spacing:  0.028rem;
    box-sizing:      border-box;
    position:        relative;
    color:           #ffffff;
    font-weight:     600;
    text-decoration: none;

    @media only screen and (max-width: (@desktop)) {
        padding:        0 0.78rem;
        letter-spacing: 0.015rem;
    }

    @media only screen and (max-width: (@big_width)) {
        padding:     0 16px 0 36px;
        line-height: 36px;
        font-size:   14px;
        font-weight: 600;
        display:     block;
        width:       100%;
        text-align:  left;
    }

    &.active {
        background-color: rgba(205, 230, 210, 0.15);

        &:before {
            content:    '';
            position:   absolute;
            background: @green3;
            width:      100%;
            height:     4px;
            bottom:     0;
            left:       0;

            @media only screen and (max-width: (@big_width)) {
                width:  4px;
                height: 100%;
            }
        }
    }

    &:active {
        background-color: rgba(205, 230, 210, 0.15);
        color:            #ffffff;
    }

    &:hover,
    &:focus {
        background-color: rgba(205, 230, 210, 0.1);
    }

    &Mobile {
        padding:     0 16px 0 36px;
        line-height: 36px;
        font-size:   14px;
        font-weight: 600;
        display:     block;
        width:       100%;
        text-align:  left;
    }
}

.contactsLink {
    color: @gray6;

    &:hover,
    &:active {
        color:           @gray6;
        text-decoration: underline;
        background:      transparent;
    }

    &.active {
        background: transparent;

        &:before {
            content: none;
        }
    }

    &:focus {
        background-color: transparent;
    }

    @media only screen and (max-width: (@mid_width)) {
        font-size: 12px;
    }
}

.hashLink {
    background: transparent;
    color:      @green2;
    padding:    9px 10px 9px 60px;

    &.active {
        background-color: @gray8;
        color:            @green2;
    }

    &:active {
        background-color: @gray8;
        color:            @green2;
    }

    &:hover,
    &:focus {
        background-color: @gray8;
    }
}

.dropdown {

    &List {
        background-color: #15635c;
        position:         absolute;
        display:          none;
        left:             0;
        min-width:        237px;
        font-size:        0;
        padding:          0;
        z-index:          1;
        overflow-y:       auto;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius:  3px;
        max-height:       calc(90vh - 56px);
        -webkit-box-shadow: 0 8px 8px 1px @gray1_15;
        box-shadow:         0 8px 8px 1px @gray1_15;
    }

    &:hover {
        .dropdownList {
            display: block;
        }
    }

    &Item {
        line-height: normal;
        font-size:   14px;
    }

    &Icon {
        width:     12px;
        height:    10px;
        transform: rotate(90deg);
        margin:    0 0 0 8px;
        fill:      #ffffff;
    }

    &Link {
        display:        block;
        line-height:    normal;
        position:       relative;
        padding:        8px 14px 9px 14px;
        font-size:      14px;
        letter-spacing: 0.01rem;

        &:hover,
        &:focus {
            background-color: rgba(205, 230, 210, 0.15);
        }

        &.active:before {
            content: none;
        }

        &:active {
            background-color: rgba(205, 230, 210, 0.1);
        }
    }
}

.collapse {
    position: relative;
    width:    100%;
    outline:  none !important;

    &.show {
        .collapseList {
            display: block;
        }

        .collapseIcon {
            transform: rotate(270deg);
        }
    }

    &Icon {
        width:        7.5px;
        height:       12px;
        fill:         @green3;
        transform:    rotate(90deg);
        transition:   transform 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
        margin-left:  -15px;
        margin-right: 15px;
    }

    &List {
        display:    none;
        list-style: none;
        padding:    0;
        animation:  fadeIn .5s ease-in;

        .navlink {
            padding-left: 57px;
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

.adminNavlink {
    display:         inline-block;
    vertical-align:  top;
    font-size:       1.12rem;
    transition:      all 0.2s ease-in-out;
    cursor:          pointer;
    padding:         0 0.9rem;
    letter-spacing:  0.028rem;
    box-sizing:      border-box;
    position:        relative;
    color:           #ffffff;
    font-weight:     600;
    text-decoration: none;

    @media only screen and (max-width: (@desktop)) {
        padding:        0 0.78rem;
        letter-spacing: 0.015rem;
    }

    @media only screen and (max-width: (@big_width)) {
        line-height: 60px;
    }

    @media only screen and (max-width: (@mid_width)) {
        font-size: 12px;
    }

    &.active {
        background-color: rgba(205, 230, 210, 0.1);

        &:before {
            content:    '';
            position:   absolute;
            background: @green3;
            width:      100%;
            height:     4px;
            bottom:     0;
            left:       0;
        }
    }

    &:active {
        background-color: rgba(205, 230, 210, 0.1);
        color:            #ffffff;
    }

    &:hover,
    &:focus {
        background-color: rgba(205, 230, 210, 0.15);
    }
}
