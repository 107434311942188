@import "../../skin/css/sg/variables/variables_less";
@import "../../skin/css/sg/mixins_less";

.btn {
    padding:          0 20px;
    box-sizing:       border-box;
    border-radius:    3px;
    border-style:     hidden;
    background-color: transparent;
    cursor:           pointer;
    display:          inline-block;
    height:           36px;
    color:            #ffffff;
    font-size:        14px;
    font-weight:      600;
    line-height:      36px;
    letter-spacing:   0.23px;
    text-decoration:  none;
    text-align:       center;
    transition:       all 0.2s ease-out;
    outline:          none;
    .appearance();

    &Disabled,
    &:disabled {
        cursor:         default;
        opacity:        0.3;
        pointer-events: none;
    }

    /*  btn-primary */

    &Primary {
        &:extend(.btn);
        background-color: @primary;

        /*  btn-primary-glass   */

        &Glass {
            &:extend(.btn);
            color:  @primary;
            border: 1px solid @primary;
            flex:   none;

            &Check {
                &:extend(.btn);
                border: 1px solid @gray6;
                color:  @gray1;
                cursor: default;
                flex:   none;

                &:hover,
                &:focus,
                &:active,
                &:visited {
                    border-color: @gray6;
                }
            }
        }

        /*  hover, focus, active, disabled  */

        &, &Glass {
            &:hover,
            &:focus {
                color:            #ffffff;
                border-color:     @primary--hover;
                background-color: @primary--hover;
            }

            &:active {
                color:            @primary--active-text;
                border-color:     @primary--active;
                background-color: @primary--active;
            }

            &Disabled,
            &:disabled {
                &:extend(.btn:disabled);
            }
        }
    }
    /*  btn-green primary */

    &Green {
        font-family: 'Montserrat', 'Open Sans', Courier, monospace;
        &:extend(.btn);
        padding:          0 22px;
        background-color: @green;

        &:hover,
        &:active {
            background-color: @green_hover;
        }

        /*  btn-green glass */

        &Glass {
            color:  @green;
            border: 1px solid @green;

            &:hover {
                background-color: @green7;
            }
        }
    }
    /*  btn-secondary   */

    &Secondary {
        &:extend(.btn);
        color:            @gray3;
        background-color: @gray7;

        &:active {
            color:            @gray4;
            background-color: @gray6;
        }

        &:hover,
        &:focus {
            color:            @gray3;
            border-color:     @gray8;
            background-color: @gray8;
        }

        &Disabled,
        &:disabled {
            &:extend(.btn:disabled);
        }

        /*  btn-secondary-glass */

        &Glass {
            &:extend(.btn);
            color:  @gray5;
            border: 1px solid @gray6;
            flex:   none;

            &:active {
                border-color:     @gray6;
                background-color: @gray6;
            }
        }

        &Glass {
            &:hover,
            &:focus {
                border-color:     @gray8;
                background-color: @gray8;
            }

            &Disabled,
            &:disabled {
                &:extend(.btn:disabled);
            }
        }

        &Dark {
            &:extend(.btn);
            background-color: @gray4;

            &:hover,
            &:focus {
                color:            #ffffff;
                background-color: @gray5;
            }

            &:active {
                color:            @gray2;
                background-color: @gray3;
            }
        }
    }

    /*btn-special*/

    &Special {
        &:extend(.btn);
        background-color: @special;

        &:active {
            color:            @special--active-text;
            background-color: @special--active;
        }

        &Glass {
            &:extend(.btn);
            border: 1px solid @special;

            &:active {
                border-color:     @special--active;
                background-color: @special--active;
            }
        }

        &, &Special {
            &:hover,
            &:focus {
                border-color:     @special--hover;
                background-color: @special--hover;
            }

            &Disabled,
            &:disabled {
                &:extend(.btn:disabled);
            }
        }
    }

    /*btn-danger*/

    &Danger {
        &:extend(.btn);
        background-color: @danger;


        &:hover,
        &:focus {
            background-color: @danger--hover;
        }

        &:active {
            color:            @danger--active-text;
            background-color: @danger--active;
        }

        &Disabled,
        &:disabled {
            &:extend(.btn:disabled);
        }
    }
}

/*  Wrappers    */

.btnBlock {
    display:         flex;
    flex-grow:       1;
    align-items:     center;
    justify-content: flex-start;
    box-sizing:      border-box;
    margin-top:      25px;

    &Right {
        &:extend(.btnBlock);
        justify-content: flex-end;
    }

    &Left {
        &:extend(.btnBlock);
        justify-content: flex-start;
    }

    & *:not(:first-child),
    &Right *:not(:first-child),
    &Left *:not(:first-child) {
        margin-left: 20px;
    }

    @media only screen and (max-width: (@middle_mobile)) {
        justify-content: space-between;

        & *,
        &Right *,
        &Left * {
            flex: 1 0 0;
        }
    }

    &Column {
        &:extend(.btnBlock);
        .flex-direction--media();

        & *:not(:first-child) {
            margin-left: 20px;
        }

        @media only screen and(max-width: (@big_mobile)) {
            margin-left:  auto;
            margin-right: auto;
            max-width:    270px;

            & * {
                width: 100%;
            }

            & *:not(:first-child) {
                margin-left: 0;
                margin-top:  10px;
            }
        }
    }

    &Centered {
        display:         flex;
        justify-content: center;
    }

    //что-то совсем не то с переносом старых
    &Justify {
        display:         flex;
        justify-content: space-between;
    }
}

.btnLarge {
    font-size:   16px;
    height:      40px;
    line-height: 40px;
    padding:     0 22px;

    @media only screen and(max-width: (@big_mobile)) {
        font-size:   14px;
        height:      36px;
        line-height: 36px;
        padding:     0 20px;
    }
}
