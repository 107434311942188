.themeSlider {
  :global {
    .splide__arrows {
      position: absolute;
      bottom: -60px;
      left: 0px;
      width: 100%;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
  
    }
    .splide__arrow {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      transform: none;
      width: 40px;
      background: none;
      font-size: 0;
    }
    
    .splide__arrow--next svg {
      display: none;
    }
    
    .splide__arrow--next::before {
      content: "";
      background-image: var(--next-arrow-url);
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      width: 40px;
      height: 30px;
    }
    
    .splide__arrow--prev svg {display: none;}
    
    .splide__arrow--prev::before {
      content: "";
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      width: 40px;
      height: 30px;
      background-image: var(--prev-arrow-url);
    }
    
    @media (min-width: 552px) {
      .splide__arrow--prev::before,
      .splide__arrow--next::before,
      .splide__arrow {
        width: 50px;
      }
    }
  }
}

@media (min-width: 992px) {
  .themeFlex {
    display: flex!important;
    flex-direction: row;
    justify-content: center;
    grid-gap: 20px;
  }
}