@import '../../../skin/css/sg/variables/variables_less';
@import '../../../skin/css/sg/mixins_less';

.contactsIconsItem {
    display:      block;
    margin-right: 10px;

    @media only screen  and (max-width: (@big_mobile_horizontal)) {
        margin: 0 5px;
    }
}
