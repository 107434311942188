@import '../../../../../skin/css/sg/variables/variables_less';
@import '../../../../../skin/css/sg/mixins_less';

.authorization {
    display:     flex;
    align-items: center;
    margin-left: auto;

    &Admin {
        display:        inline-block;
        vertical-align: top;

        @media only screen and (max-width: (@max_width)) {
            display: none;
        }
    }

    &Inner {
        display:     flex;
        align-items: center;
        height:      100%;

        @media only screen and (max-width: (@big_width)) {
            display: none;
        }
    }
}

.authpanel {
    position:        relative;
    vertical-align:  top;
    height:          100%;
    display:         flex;
    justify-content: center;
    align-items:     center;
    padding:         0 12px;

    &:hover {
        background-color: rgba(205, 230, 210, 0.1);

        .authMenu {
            display: block;
        }
    }
}

.authData {
    position:      relative;
    padding-right: 22px;
    display:       flex;
    align-items:   center;

    &Description {
        vertical-align: middle;
        display:        inline-block;
        position:       relative;
    }

    &Avatar {
        width:         100%;
        border-radius: 50%;
    }

    &Picture {
        border-radius:    50%;
        text-align:       center;
        height:           40px;
        height:           2.857rem;
        width:            40px;
        width:            2.857rem;
        font-size:        20px;
        font-size:        1.429rem;
        font-weight:      700;
        line-height:      40px;
        line-height:      2.857rem;
        color:            @gray6;
        background-color: @green7;
        text-transform:   uppercase;
        overflow:         hidden;
        font-family:      'Open Sans Condensed', sans-serif;

        &Avatar {
            background-size:     40px;
            background-position: center top;
            background-repeat:   no-repeat;
            border-radius:       50%;
            width:               40px;
            height:              40px;
        }
    }

    &Overlay {
        border-radius: 50%;
        position:      absolute;
        bottom:        -7px;
        bottom:        -0.5rem;
        right:         -7px;
        right:         -0.5rem;
        left:          -7px;
        left:          -0.5rem;
        top:           -7px;
        top:           -0.5rem;
    }

    &Link {
        text-overflow:   ellipsis;
        display:         inline-block;
        vertical-align:  top;
        white-space:     nowrap;
        margin-left:     6px;
        overflow:        hidden;
        max-width:       74px;
        transition:      all 0.2s ease-in-out;
        cursor:          pointer;
        color:           #ffffff;
        font-weight:     600;
        text-decoration: none;
        letter-spacing:  0.01rem;
        font-size:       14px;
    }

    &Icon {
        position:   absolute;
        fill:       #ffffff;
        top:        50%;
        right:      0;
        margin-top: -5px;
        transform:  rotate(90deg);
        width:      10px;
        height:     10px;
    }
}

.authMenu {
    list-style-type:            none;
    margin:                     0;
    -webkit-box-shadow:         0 8px 8px 1px @gray1_15;
    box-shadow:                 0 8px 8px 1px @gray1_15;
    background-color:           #15635c;
    position:                   absolute;
    min-width:                  170px;
    padding:                    8px 0;
    display:                    none;
    top:                        69px;
    right:                      -2px;
    z-index:                    3;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius:  3px;

    &Icon {
        fill:          @gray6;
        font-size:     15px;
        margin-right:  7px;
        margin-bottom: -2px;
        display:       inline-block;
        width:         1em;
        height:        1em;
        transition:    all 0.2s ease-in-out;
    }

    &Link {
        font-size:       14px !important;
        font-weight:     600;
        transition:      all 0.2s ease-in-out;
        cursor:          pointer;
        text-decoration: none !important;
        color:           #ffffff !important;
        position:        relative;
        padding:         0 15px 0 18px !important;
        display:         block;
        width:           100%;
        text-align:      left;
        line-height:     36px !important;

        &Logout {
            padding: 7px 15px 7px 18px !important;
        }

        &:hover {
            text-decoration:  none;
            color:            #ffffff;
            background-color: rgba(205, 230, 210, 0.2);

            .authMenuIcon {
                fill: @special;
            }
        }

        @media only screen and (max-width: (@mobile)) {
            font-size:      14px;
            letter-spacing: 0.02rem;
        }
    }

    &Item {
        line-height: 36px;

        &Divider {
            height:     4px;
            margin:     8px 0 4px 16px;
            width:      68%;
            border-top: 1px solid rgba(235, 245, 240, 0.2)
        }

        .authMenuLink {
            font-size:       14px;
            text-decoration: none !important;

            &:hover,
            &:active {
                text-decoration: none;
            }
        }
    }
}

.cart {
    &Link {
        margin-top:  5px;
        margin-left: 23px;
        position:    relative;
    }

    &Icon {
        width:  26px;
        height: 22px;
        fill:   #ffffff;
    }
}
