.insidetrackerWorks {
  padding: 103px 0px 136px;
  background-color: #EEF7F0;
}
.insidetrackerWorks.stepsHeight {
  height: 500vh;
}

.insidetrackerWorksContainer {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.insidetrackerWorksWrap .sectionTitle {
  font-family: "Montserrat", serif;
  font-size: 50px;
  line-height: 62px;
  font-weight: 700;
  color: #005550;
  text-align: center;
  margin: 0px;
}

.workStepsWrap {
  margin-top: 48px;
  text-align: center;
}

.workStepsWrap .workSteps {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 104px;
}

.workStepsWrap .workSteps .workStep {
  width: calc(25% - 104px);
  flex-grow: 1;
  text-align: center;
}

.workStepsWrap .workSteps .workStep .image {
  width: 124px;
  height: 124px;
  margin: 0 auto;
}

.workStepsWrap .workSteps .workStep .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.workStepsWrap .workSteps .workStep .subTitle {
  font-family: "Montserrat", serif;
  font-size: 15px;
  line-height: 27px;
  font-weight: 700;
  color: #005550;
  margin: 0px;
}

.workStepsWrap .workSteps .workStep .title {
  font-family: "Montserrat", serif;
  font-size: 22.5px;
  line-height: 27.8px;
  font-weight: 700;
  color: #005550;
  margin: 0px;
}

.workStepsWrap .workSteps .workStep .content {
  font-family: "Open Sans", serif;
  font-size: 13.5px;
  line-height: 19.5px;
  font-weight: 400;
  color: #232D37;
  margin: 0px;
}

.workStepsWrap .stepsBtn {
  font-family: "Open Sans", serif;
  padding: 12px 30px;
  text-decoration: none;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: .3px;
  font-weight: 600;
  color: #ffffff;
  background-color: #00A050;
  margin-top: 30px;
  display: inline-block;
}

/* Default state for steps */
.workStep {
  opacity: 0.4;
  transform: scale(1);
}

@media (max-width: 1200px) {
  .insidetrackerWorksWrap {
    padding: 0px 20px;
  }
  .insidetrackerWorksContainer {
    width: 100%;
    max-width: 100%;
    padding: 0px;
  }

  .workStepsWrap .workSteps .workStep {
    max-width: 185px;
  }
  .workStepsWrap .workSteps {
    gap: 52px;
  }
}

@media (max-width: 768px) {
  .insidetrackerWorks.stepsHeight {
    height: auto;
  }
  .workStepsWrap .workSteps {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
  .workStepsWrap .workSteps .workStep {
    width: 100%;
  }
  .workStepsWrap .workSteps .workStep .image {
    width: 117px;
    height: 117px;
    margin: 0 auto;
  }
  .workStepsWrap .workSteps .workStep .subTitle {
    font-size: 20px;
    line-height: 36px;
  }
  .workStepsWrap .workSteps .workStep .title {
    font-size: 30px;
    line-height: 37px;
  }
  .workStepsWrap .workSteps .workStep .content {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 400px) {
  .insidetrackerWorksWrap .sectionTitle {
    font-size: 26px;
    line-height: 30px;
  }
}