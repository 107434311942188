.productCard {
  position: relative;
  padding: 50px 15px 32px;
  text-align: center;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  max-width: 280px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.productCardTag {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 10px;
  line-height: 15px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  padding: 6px 12px;
  background-color: #00A050;
  color: #fff;
  border-radius: 15px 0 15px 0;
}
.productCardTitle {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #005550;
  margin-bottom: 30px;
}
.productCardImage {
  display: block;
  margin: 0 auto 20px;
  max-width: 200px;
  max-height: 200px;
}
.productCardTagTitle {
  font-size: 11px;
  line-height: 17px;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 15px;
  font-weight: 600;
}
.productCardCats {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  padding: 0;
}
.productCardCats li {
  font-size: 7px;
  line-height: 10px;
  color: #232D37;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  background-color: #E1E4E6;
  margin-bottom: 10px;
  letter-spacing: .03px;
  border-radius: 22px;
  margin: 5px 4px 5px 0px;
}
.productCardCats li svg {
  max-width: 10px;
  max-height: 10px;
  margin-right: 2px;
}

.productCardPrice {
  font-size: 23px;
  line-height: 15px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: auto;
}
.productCardPrice span {
  font-size: 11px;
  line-height: 19px;
  font-weight: 600;
  margin-left: 8px;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  color: #000000;
}
.productCardInfo {
  font-size: 11px;
  line-height: 17px;
  color: #5D676F;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  margin-bottom: 15px;
}

.productCardButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: .03px;
  font-family: 'Open Sans', regular;
  font-weight: 600;
  color: #fff;
  background-color: #00A050;
  padding: 9px 24px;
  text-transform: inherit;
  text-align: center;
  margin: 0 auto;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  border-radius: 3px;
  border: 1px solid #00A050;
  text-decoration: none;
  -webkit-transition: .2s all ease-in-out;
  -o-transition: .2s all ease-in-out;
  transition: .2s all ease-in-out;
}

.productCardButton:hover,
.productCardButton:focus,
.productCardButton:focus-visible,
.productCardButton:active {
    background-color: #00825a;
    border-color: #00825a;
}
@media (min-width: 552px) {
  .productCard {
    padding: 60px 20px 45px;
    border-radius: 20px;
    max-width: 380px;
  }
  .productCardTag {
    font-size: 12px;
    line-height: 18px;
    padding: 8px 12px;
    border-radius: 20px 0 20px 0;
  }
  .productCardImage {
    max-width: 300px;
    max-height: 300px;
  }
  .productCardTitle {
    font-size: 28px;
    line-height: 32px;
  }
  .productCardTagTitle {
    font-size: 12px;
    line-height: 20px;
  }
  .productCardCats li {
    font-size: 9px;
    line-height: 12px;
  }
  .productCardCats li svg {
    max-width: 12px;
    max-height: 12px;
    margin-right: 3px;
  }
  .productCardPrice {
    font-size: 26px;
    line-height: 18px;
  }
  .productCardPrice span {
    font-size: 12px;
    line-height: 21px;
  }
  .productCardInfo {
    font-size: 12px;
    line-height: 20px;
  }
  .productCardButton {
    padding: 10px 28px;
  }
}
@media (min-width: 992px) {
  .productCardTag {
    font-size: 12px;
    line-height: 18px;
    padding: 8px 12px;
  }
  .productCardTitle {
    font-size: 28px;
    line-height: 32px;
  }
  .productCardTagTitle {
    font-size: 14px;
    line-height: 22px;
  }
  .productCardCats li {
    font-size: 10px;
    line-height: 14px;
  }
  .productCardCats li svg {
    max-width: 14px;
    max-height: 14px;
    margin-right: 4px;
  }
  .productCardPrice {
    font-size: 30px;
    line-height: 20px;
  }
  .productCardPrice span {
    font-size: 14px;
    line-height: 25px;
  }
  .productCardInfo {
    font-size: 14px;
    line-height: 22px;
  }
  .productCardButton {
    padding: 12px 32px;
  }
}