@import "../../skin/css/sg/variables/variables_less";
@import "../../skin/css/sg/mixins_less";

.paperBlurb {
    display:     flex;
    align-items: center;
    margin-top:  15px;

    @media only screen and (max-width: 940px) {
        justify-content: center;
    }
    @media only screen and (max-width: (@big_tablet)) {
        flex-direction: column;
        margin-top:     25px;
    }

    &Icon {
        width:  70px;
        height: 70px;
    }

    &Title {
        font-weight: 600;

        @media only screen and (max-width: (@big_tablet)) {
            display: block;
        }
    }

    &Text {
        padding-left: 20px;
        font-size:    14px;
        line-height:  20px;

        @media only screen and (max-width: (@big_tablet)) {
            padding-left: 0;
            margin-top:   5px;
        }
    }
}
