@import "../../../../skin/css/sg/variables/variables_less";
@import "../../../../skin/css/sg/mixins_less";

@import "../../../styles/statement";
@import "../../../styles/container";
@import "../../../styles/links";

.PressPage {
  overflow-x: hidden;
  &Section {
    margin-top:       69px;
    background-color: @gray8;

    @media only screen and (max-width: (@big_width)) {
      margin-top: 60px;
    }

    @media only screen and (max-width: 600px) {
      margin-top: 49px;
    }
  }

  &Imagery {
    &Item {
      height: 375px !important;
    }

    &Text {
      display:        block;
      line-height:    0.95;
      letter-spacing: -2px;
      padding:        100px 0 30px;
    }

    &Btn {
      margin-left: 7px;
    }

    &Container {
      display: block !important;
    }
  }

  &Title {

    &Slim {
      font-weight: normal;
    }
  }
}

.Reviews {
  padding-bottom: 174px;

  @media only screen and (max-width: 440px) {
    padding-bottom: 71px;
  }

  &Title {
    font-family:    'Open Sans Condensed', sans-serif;
    height:         34px;
    color:          @gray1;
    font-size:      45px;
    font-weight:    700;
    line-height:    0.92;
    text-align:     left;
    text-transform: uppercase;
    letter-spacing: 0px;
    word-spacing:   -3px;
    display:        block;
    margin:         74px auto 62px;

    @media only screen and(max-width: 440px) {
      height: 74px;
      margin: 30px auto 35px;
    }
  }

  &Card {
    display:        flex;
    flex-direction: column;
    align-items:    flex-start;
    flex-basis:     73%;

    @media only screen and (max-width: 820px) {
      flex-basis: 65%;
    }
    @media only screen  and(max-width: 820px) {
      flex-basis: 60%;
    }
    @media only screen and (max-width: 600px) {
      flex-basis: auto;
    }

    &Wrapper {
      display:          flex;
      justify-content:  space-between;
      min-width:        288px;
      min-height:       140px;
      max-height:       360px;
      box-shadow:       0 4px 12px 1px @gray1_15;
      background-color: #ffffff;
      box-sizing:       border-box;
      padding:          24px 29px 20px;
      margin:           0 0 16px 0;
      transition:       all 0.2s ease-in-out;

      @media only screen and (max-width: 600px) {
        flex-direction: column-reverse;
        padding:        16px;
      }

      @media only screen and (max-width: 430px) {
          max-height: initial;
      }

      &:hover,
      &:focus {
        box-shadow: 0 6px 15px 1px @gray1_24;
      }
    }

    &Date {
      color:       @gray5;
      font-size:   12px;
      line-height: 1.33;
      text-align:  left;
      padding:     0 0 2px;
      margin:      0;

      @media only screen and (max-width: 600px) {
        padding: 14px 0 2px;
      }
    }


    &Title {
      width:        100%;
      color:        @gray1;
      font-size:    20px;
      font-weight:  600;
      word-spacing: 2px;
      padding:      5px 0 5px;
      margin:       0;

      @media only screen and (max-width: 600px) {
        padding-top: 3px;
      }
    }

    &Text {
      width:       100%;
      color:       @gray1;
      line-height: 1.4;
      padding:     0;
      margin:      0;

      @media only screen and (max-width: 600px) {
        font-size: 0.8em;
      }
    }

    &Link {
      padding: 0;
      margin:  0;
    }

    &Logo {
      display:    flex;
      flex-basis: 22%;
      height:     100%;
      margin:     auto;

      svg {
        max-height: 96px;
      }

      @media only screen and(max-width: 600px) {
        align-self:  flex-start;
        flex-basis:  auto;
        margin-left: 0;

        svg {
          max-width: 200px;
          margin:    0 auto 0 0;
        }
      }

      &Img {
        width:      auto;
        max-width:  180px;
        height:     auto;
        margin:     auto;
        text-align: center;

        @media only screen and(max-width: 600px) {
          max-width: inherit;
        }
      }
    }
  }
}

.Releases {
  &ImageryText {
    padding:        132px 0 0;
    margin:         0 0 0 -8px;
    letter-spacing: -2px;
    line-height:    0.95;

      @media only screen and (max-width: (@big_mobile)) {
          font-size: 60px !important;
      }
  }

  &Breadcrumbs {
    margin: 26px 0 35px;

    @media only screen and(max-width: 440px) {
      margin: 30px 0 32px;
    }

    &Link {
      font-weight: 600;

      &Icon {
        width: 40px;
      }

      &Disabled {
        color:           @gray5;
        font-weight:     600;
        cursor:          default;
        text-decoration: none;
        opacity:         0.3;

        &:hover,
        &:focus {
          color:           @gray5;
          text-decoration: none;
        }
      }
    }
  }

  &Card {
    flex-basis: 76%;

    @media only screen and(max-width: 940px) {
      flex-basis: 60%;
    }
    @media only screen and(max-width: 600px) {
      flex-basis: auto;

    }

    &Wrapper {
      min-height: 120px;
      max-height: 320px;
      height:     120px;
      padding:    12px 29px 12px;

      @media only screen and(max-width: 1250px) {
        height: auto;
      }
      @media only screen and (max-width: 600px) {
        max-height: 280px;
      }
      @media only screen and(max-width: 440px) {
        max-height:    320px;
        padding:       16px;
        margin-bottom: 16px;
      }
    }

    &Title {
      letter-spacing: 0.1px;
      font-size:      16px;
      padding:        5px 0 5px;

      @media only screen and(max-width: 440px) {
        padding:     4px 0 5px;
        line-height: 1.4;
      }
    }

    &Date {
      margin: 8px 0 1px 0;
    }

    &Link {
      margin:      0;
      padding:     7px 0 0 0;
      display:     flex;
      align-items: center;

      &Icon {
        margin-right: 8px;

        path {
          transition: fill 0.5s;
        }
      }

      &:hover {
        text-decoration: none;

        svg {
          path {
            fill: @green;
          }
        }
      }
    }
  }
}
